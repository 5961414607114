import { Button, Card, Col, Form, Input, Row } from "antd";
import usecustomStyles from "../../Common/customStyles";
// import { Facebook } from "lucide-react";
// import {
//   GithubOutlined,
//   GoogleOutlined,
//   TwitterOutlined,
// } from "@ant-design/icons";

import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ParticleAuth from "../../Common/ParticleAuth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { adminSignUp } from "../../slices/thunk";
import Spinners from "../../Common/Spinner";
import useToast from "../../hooks/useToast";
// import { useThunk } from "../../hooks/useThunk";

const customStyles = usecustomStyles();

// const StyleLine = styled.div`
//   .signin-other-title {
//     position: relative;
//     &:after {
//       content: "";
//       position: absolute;
//       width: 100%;
//       height: 1px;
//       left: 0;
//       right: 0;
//       border-top: 1px dashed #eff2f7;
//       top: 10px;
//     }
//     .title {
//       display: inline-block;
//       position: relative;
//       z-index: 9;
//       background-color: #d0d0d0;
//       padding: 2px 16px;
//     }
//   }
// `;

const StyleWrapper = styled.div`
  background-color: ${({ theme }) => theme.token.authbgcolor};
  width: 100%;
`;

const AuthSignUp = () => {
  // page title
  document.title = "Sign Up";

  // const [adminSignUpApi] = useThunk(adminSignUp);

  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.Account);
  const showToast = useToast();
  const navigate = useNavigate();

  console.log("loading...", loading);
  // console.log("success...", success);
  console.log("error...", error);
  // console.log("registrationError...", registrationError);
  console.log("user...", user);

  const onFinish = (data) => {
    const body = {
      name: data.firstName + "+" + data.lastName,
      email: data.email,
      password: data.password,
      password_confirmation: data.confirmPassword,
      phone: data.phone,
      registration_key: data.registrationKey,
    };
    dispatch(adminSignUp(body))
      .unwrap()
      .then(() => {
        showToast("success", "Sign Up Success");
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log("error in toast", error);
        showToast("error", error.message);
      });
  };

  return (
    <StyleWrapper className="auth-page-wrapper">
      {loading && <Spinners />}
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col xs={24} lg={20} xxl={14}>
          <Card>
            <Row gutter={[16, 24]}>
              <ParticleAuth />
              <Col xs={24} lg={14}>
                <Card style={{ border: "0px" }}>
                  <div className="text-center" style={{ margin: "20px" }}>
                    <h5
                      style={{
                        fontSize: "20px",
                        color: customStyles.colorPrimary,
                      }}
                    >
                      Create New Account
                    </h5>
                  </div>
                  <div>
                    <Form name="signup-form" onFinish={onFinish}>
                      <Row gutter={[16, 24]}>
                        <Col xs={24} sm={12}>
                          <div>
                            <label
                              style={{ marginBottom: "4px", display: "block" }}
                            >
                              First Name{" "}
                              <span style={{ color: customStyles.colorDanger }}>
                                *
                              </span>
                            </label>
                            <Form.Item
                              name="firstName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your First Name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="First Name"
                                style={{ outline: "none", boxShadow: "none" }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <div>
                            <label
                              style={{ marginBottom: "4px", display: "block" }}
                            >
                              Last Name{" "}
                              <span style={{ color: customStyles.colorDanger }}>
                                *
                              </span>
                            </label>
                            <Form.Item
                              name="lastName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Last Name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Last Name"
                                style={{ outline: "none", boxShadow: "none" }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={[16, 24]}>
                        <Col xs={24} sm={12}>
                          <div>
                            <label
                              style={{ marginBottom: "4px", display: "block" }}
                            >
                              Email{" "}
                              <span style={{ color: customStyles.colorDanger }}>
                                *
                              </span>
                            </label>
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Email",
                                },
                                {
                                  type: "email",
                                  message: "Please enter a valid Email",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Email"
                                style={{ outline: "none", boxShadow: "none" }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <div>
                            <label
                              style={{ marginBottom: "4px", display: "block" }}
                            >
                              Phone{" "}
                              <span style={{ color: customStyles.colorDanger }}>
                                *
                              </span>
                            </label>
                            <Form.Item
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Phone number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Phone"
                                style={{ outline: "none", boxShadow: "none" }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={[16, 24]}>
                        <Col xs={24} sm={12}>
                          <div>
                            <label
                              style={{ marginBottom: "4px", display: "block" }}
                            >
                              Password{" "}
                              <span style={{ color: customStyles.colorDanger }}>
                                *
                              </span>
                            </label>
                            <Form.Item
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Password",
                                },
                                {
                                  pattern:
                                    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                  message:
                                    "Password should contain at least one character, one special character, and one number",
                                },
                              ]}
                            >
                              <Input.Password
                                placeholder="Password"
                                style={{ outline: "none", boxShadow: "none" }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <div>
                            <label
                              style={{ marginBottom: "4px", display: "block" }}
                            >
                              Confirm Password{" "}
                              <span style={{ color: customStyles.colorDanger }}>
                                *
                              </span>
                            </label>
                            <Form.Item
                              name="confirmPassword"
                              dependencies={["password"]}
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "Please confirm your Password",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("password") === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        "The two passwords do not match"
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input.Password
                                placeholder="Confirm Password"
                                style={{ outline: "none", boxShadow: "none" }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={[16, 24]}>
                        <Col xs={24} sm={12}>
                          <div>
                            <label
                              style={{ marginBottom: "4px", display: "block" }}
                            >
                              Registration Key{" "}
                              <span style={{ color: customStyles.colorDanger }}>
                                *
                              </span>
                            </label>
                            <Form.Item
                              name="registrationKey"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Registration Key",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Registration Key"
                                style={{ outline: "none", boxShadow: "none" }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Form.Item>
                        <div>
                          <p
                            style={{
                              fontSize: "12px",
                              fontStyle: "italic",
                              marginBottom: "0px",
                            }}
                          >
                            By registering you agree to the Lizant{" "}
                            <Link
                              href="/#"
                              style={{
                                textDecoration: "underline",
                                fontStyle: "normal",
                              }}
                            >
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ width: "100%" }}
                        >
                          Sign Up
                        </Button>
                      </Form.Item>
                    </Form>

                    <div style={{ marginTop: "50px" }} className="text-center">
                      <p>
                        Already have an account ?{" "}
                        <Link
                          to="/auth-signin"
                          style={{
                            marginRight: "5px",
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          Sign In
                        </Link>
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </StyleWrapper>
  );
};

export default AuthSignUp;
