import { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Switch,
  Row,
  Col,
  Upload,
  Typography,
  Select,
  message,
} from "antd";
import { getAllCourseCategories, addNewCourse } from "../../slices/thunk";
import { useThunk } from "../../hooks/useThunk";
import {
  ArrowLeftOutlined,
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import usecustomStyles from "../../Common/customStyles";
import { useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { data } from "autoprefixer";
import Spinners from "../../Common/Spinner";
import UploadImage from "../../Common/UploadImage";

const customStyles = usecustomStyles();
// const { TextArea } = Input;
const { Text } = Typography;

const AddCourse = () => {
  // const { id } = useParams();
  const courseCategoriesData = useSelector(
    (state) => state?.Course?.allCourseCategories
  );
  const currentUser = useSelector((state) => state?.Login?.user);
  // const currentUserString = localStorage.getItem("currentUser");
  // if (!currentUserString) {
  //   throw new Error("No currentUser found in localStorage");
  // }
  // const currentUser = JSON.parse(currentUserString);
  // const token = currentUser.token;
  const isLoading = useSelector((state) => state?.Course?.loading);
  const error = useSelector((state) => state?.Course?.error);
  // const [courseData, setCourseDatat] = useState({});
  const dispatch = useDispatch();
  if (error) {
    console.log("check the error", error);
    message.error(error.message);
  }
  useEffect(() => {
    console.log("check the env", process.env.REACT_APP_BASE_URL);
    dispatch(getAllCourseCategories());
  }, []);

  useEffect(() => {
    console.log("check the currentUser", currentUser);
    // dispatch(getAllCourseCategories());
  }, [currentUser]);

  const [form] = Form.useForm();

  const [editorValue, setEditorValue] = useState({
    content: "",
    requirements: "",
    description: "",
  });
  const navigate = useNavigate();

  const validateCustom = (fieldNames, formObject) => {
    const formValues = formObject.getFieldsValue([...fieldNames]);
    const messagesList = fieldNames
      .map((fieldName) =>
        formValues[fieldName]?.length ? "" : `${fieldName} is required`
      )
      .filter((message) => message !== "");
    if (messagesList.length) {
      // message.error(`<p>${messagesList.join('<br>')}</p>`);
      messagesList.map((messageVal) => {
        message.error(messageVal);
      });
      return false;
    }
    return true;
  };

  const [bannerImage, setBannerImage] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState([]);
  
  const handleSubmit = () => {
    const body = {
      ...form.getFieldsValue(),
      course_category_id: form.getFieldValue("category"),
      content: form.getFieldValue("content"),
      requirements: form.getFieldValue("requirements"),
    };
    // console.log("Form Data Body:", body);
    if (validateCustom(["content", "requirements", "description"], form)) {
      const formData = new FormData();
      Object.keys(body).forEach(async (key) => {
        // console.log('visa values: ', data[key])
        formData.append(`${key}`, body[key]);
      });
      formData.append(
        "is_top_rated",
        form.getFieldValue("is_top_rated") ? 1 : 0
      );
      if (thumbnailImage.length > 0) {
        if (thumbnailImage[0]?.originFileObj) {
          const ext = thumbnailImage[0].originFileObj.name.slice(
            thumbnailImage[0].originFileObj.name.lastIndexOf(".")
          );
          formData.append(
            "thumbnail",
            thumbnailImage[0]?.originFileObj,
            `${currentUser?.user.id}_${Date.now()}_thumbnail_image_url${ext}`
          );
        } else if (thumbnailImage[0]?.uid === "-1") {
          formData.append("banner_url", thumbnailImage[0]?.url);
        }
        console.log("check the list of formData", formData);
        // dispatch(addNewCourse(formData));
      } else {
        message.error("Please upload thumbnail image");
        return false;
      }
      if (bannerImage.length > 0) {
        if (bannerImage[0]?.originFileObj) {
          const ext = bannerImage[0].originFileObj.name.slice(
            bannerImage[0].originFileObj.name.lastIndexOf(".")
          );
          formData.append(
            "banner",
            bannerImage[0]?.originFileObj,
            `${currentUser?.user.id}_${Date.now()}_banner_image_url${ext}`
          );
        } else if (bannerImage[0]?.uid === "-1") {
          formData.append("banner_url", bannerImage[0]?.url);
        }
        console.log("check the list of formData", formData);
        dispatch(addNewCourse(formData));
      } else {
        // formData.append("banner", "");
        message.error("Please upload banner image");
        return false;
      }
    }
  };

  useEffect(() => {
    var list = Object.values(editorValue);
    console.log("check the editor val", list);
    if (list.length) {
      if (editorValue.content !== "") {
        form.setFieldValue("content", editorValue.content);
      }
      if (editorValue.requirements !== "") {
        form.setFieldValue(
          "requirements",
          editorValue.requirements
        );
      }
      // if (editorValue.description !== '' ) {
      //   form.setFieldValue("description", editorValue.description)
      // }
      // form.setFieldValue("content", JSON.parse(editorValue.content))
      // form.setFieldValue("requirements", JSON.parse(editorValue.requirements))
      form.setFieldValue("description", editorValue.description);
    }
  }, [editorValue]);

  // useEffect(() => {
  //   console.log("course cate values", courseCategoriesData);
  // }, [courseCategoriesData]);

  return (
    <div style={{ marginTop: "50px" }}>
      {isLoading && <Spinners />}
      <Text
        style={{
          paddingBottom: "8px",
          display: "block",
          marginBottom: customStyles.marginSM,
        }}
      >
        <Button
          type="default"
          style={{ color: "#1890ff", marginRight: "8px" }} // Set button color and margin
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate("/course/all")}
        />
        Add New Course
      </Text>
      <Card
        style={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        // initialValues={{
        //   title: '',
        //   description: '',
        //   content: ``,
        //   banner: courseData?.banner,
        //   isTopRated: false,
        //   category: '',
        // }}
        >
          <Row gutter={[16, 16]}>
            {/* left side column */}
            <Col xs={24} md={12}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input title!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter title" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Category"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Category!",
                      },
                    ]}
                  >
                    <Select
                      // mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Please select"
                      options={courseCategoriesData.map((category) => ({
                        label: category.label,
                        value: category.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Banner Image" name="banner" required>
                    <UploadImage
                      onUploadImageChange={(file) => {
                        console.log("upload", file);
                        setBannerImage(file);
                      }}
                      // passengerFileList={["", ""]}
                      passengerFileList={bannerImage ? bannerImage : []}
                      formItemKey={"banner"}
                      maxUploadCount={1}
                      cropperRatio={2.470588}
                      modalWidth={940}
                    />
                    {/* <Upload
                      beforeUpload={() => false}
                      onChange={() => {
                        console.log("upload");
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload> */}
                  </Form.Item>
                  
                </Col>
                <Col xs={12}>
                <Form.Item label="Thumbnail" name="thumbnail" required>
                    <UploadImage
                      onUploadImageChange={(file) => {
                        console.log("upload", file);
                        setThumbnailImage(file);
                      }}
                      // passengerFileList={["", ""]}
                      passengerFileList={thumbnailImage ? thumbnailImage : []}
                      formItemKey={"thumbnail"}
                      maxUploadCount={1}
                      cropperRatio={1.2724}
                      modalWidth={400}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Is Top Rated" name="is_top_rated">
                    <Switch
                      size="small"
                      // checked={courseData.is_top_rated}
                      onChange={(checked) =>
                        // setFormData({ ...formData, isTopRated: checked })
                        form.setFieldValue("is_top_rated", checked ? 1 : 0)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* right side column */}
            <Col xs={24} md={12}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Form.Item
                    noStyle
                    label="Content"
                    value={editorValue.content}
                    name="content"
                  ></Form.Item>
                  <Form.Item
                    label={<span>Content <span style={{fontWeight: 'bold', color: 'red'}}>*please use bullet not paragraph</span></span>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter content",
                      },
                    ]}
                  >
                    <ReactQuill
                      theme="snow"
                      // value={`<p>dd</p>`}
                      // defaultValue={`<p><ul>${JSON.parse(courseData.content)
                      //   .map((content) => `<li>${content}</li>`)
                      //   .join("")}</ul></p>`}
                      onChange={(content, delta, source, editor) => {
                        const listItems = editor
                          .getText()
                          .split("\n")
                          .filter((line) => line !== "");
                        console.log("test the val on contents", JSON.stringify(listItems));
                        setEditorValue({
                          ...editorValue,
                          content: JSON.stringify(listItems),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item noStyle label="Requirements" name="requirements">
                    {/* <TextArea
                        placeholder="Enter requirements"
                        rows={2}
                        value={formData.requirements}
                        onChange={(e) => handleInputChange(e, "requirements")}
                        style={{ backgroundColor: "transparent" }}
                      /> */}
                  </Form.Item>
                  <Form.Item label={<span>Requirements <span style={{fontWeight: 'bold', color: 'red'}}>*please use bullet not paragraph</span></span>}>
                    <ReactQuill
                      theme="snow"
                      // value={form.description}
                      // defaultValue={`<p><ul>${JSON.parse(courseData.requirements)
                      //   .map((content) => `<li>${content}</li>`)
                      //   .join("")}</ul></p>`}
                      onChange={(content, delta, source, editor) => {
                        const listItems = editor
                          .getText()
                          .split("\n")
                          .filter((line) => line !== "");
                        // console.log("test the val", JSON.stringify(listItems));
                        setEditorValue({
                          ...editorValue,
                          requirements: JSON.stringify(listItems),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {/* <Col xs={24}> */}
            <Col xs={24}>
              <Form.Item
                noStyle
                label="Description"
                name="description"
              ></Form.Item>
              <Form.Item label="Description">
                <ReactQuill
                  theme="snow"
                  // value={form.description}
                  // defaultValue={`${courseData.description}`}
                  onChange={(content, delta, source, editor) => {
                    console.log(
                      "test the val of description",
                      editor.getHTML()
                    );
                    setEditorValue({
                      ...editorValue,
                      description: editor.getHTML().replace("<p><br></p>", ""),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            {/* </Col> */}
          </Row>
          <Row justify="center">
            <Col xs={24} style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default AddCourse;
