import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../Common/Breadcrumb";
import SidebarEmail from "./SidebarEmail";
import Email from "./Email";
import { Col, Row } from "antd";
import { useThunk } from "../../../hooks/useThunk";
import { getAllMails } from "../../../slices/thunk";
import { useSelector } from "react-redux";

const EmailPage = () => {
  document.title = "Email" + process.env.REACT_APP_PAGE_TITLE;

  const [activeTabs, setActive] = useState("all");
  const [displayCategory, setCategory] = useState("all");
  const [displaytype, settype] = useState("all");
  const [displaylabel, setLabel] = useState("all");
  const [isLabelTab, setIsLabelTab] = useState("");
  const [isTypeTab, setIsTypeTab] = useState("primary");

  const toggleTab = (ncategory, ntype, nlabel) => {
    var element = document.getElementById("mail-filter-navlist");
    if (ncategory === "all" || ncategory === "inbox") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
    if (activeTabs !== ncategory) {
      setActive(ncategory);
    }
    if (isLabelTab !== nlabel) {
      setIsLabelTab(nlabel);
    }
    if (isTypeTab !== ntype) {
      setIsTypeTab(ntype);
    }
    setCategory(ncategory);
    settype(ntype);
    setLabel(nlabel);
  };

  //mobile screen menu bar
  const [show, setShow] = useState(false);
  const menuBarRef = useRef(null);

  const handleToggleMenuBar = () => {
    setShow(!show);
  };

  const handleClickOutside = (event) => {
    if (menuBarRef.current && !menuBarRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById("email-menu-sidebar");
    if (element) {
      if (show) {
        element.classList.add("menubar-show");
      } else {
        element.classList.remove("menubar-show");
      }
    }
  }, [show]);

  //----------------------------------------------------------------

  const [contactUsData] = useThunk(getAllMails);
  const { allContactUsMessage } = useSelector((state) => state.Email);
  console.log("contactUsData", allContactUsMessage);

  useEffect(() => {
    contactUsData();
  }, [contactUsData]);
  //----------------------------------------------------------------

  return (
    <React.Fragment>
      {/* Breadcrumb */}
      <Breadcrumb mainTitle="Apps" pageTitle="Contact-Us Messages" />

      <Row gutter={[24, 16]}>
        {/* <Col xs={24} md={6} lg={4}>
                    <SidebarEmail toggleTab={toggleTab} activeTabs={activeTabs} isLabelTab={activeTabs} menuBarRef={menuBarRef} />
                </Col> */}
        <Col
          xs={24}
          md={24}
          lg={23}
          style={{ overflowX: "auto", margin: "auto" }}
        >
          {allContactUsMessage && allContactUsMessage?.data && (
            <Email
              displayCategory={displayCategory}
              displaytype={displaytype}
              displaylabel={displaylabel}
              handleToggleMenuBar={handleToggleMenuBar}
              data={allContactUsMessage?.data}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EmailPage;
