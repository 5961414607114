import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import {
  getMailDetails as getMailDetailsApi,
  deleteMail as deleteMailApi,
  unreadMail as unreadMailApi,
  trashMail as trashMailApi,
  staredMail as staredMailApi,
  labelMail as labelMailApi,
} from "../../helpers/fakebackend_helper";

export const getMailDetails = createAsyncThunk(
  "mailbox/getMailDetails",
  async () => {
    try {
      const response = getMailDetailsApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteMail = createAsyncThunk(
  "mailbox/deleteMail",
  async (mail) => {
    try {
      const response = deleteMailApi(mail);
      toast.success("Mail Deleted Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("Mail Deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const unreadMail = createAsyncThunk(
  "mailbox/unreadMail",
  async (mail) => {
    try {
      const response = unreadMailApi(mail);
      // toast.success("Mail Added Favorite Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      // toast.error("Mail Added Favorite Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const staredMail = createAsyncThunk(
  "mailbox/staredMail",
  async (mail) => {
    try {
      const response = staredMailApi(mail);
      // toast.success("Mail Added Favorite Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      // toast.error("Mail Added Favorite Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const trashMail = createAsyncThunk("mailbox/trashMail", async (mail) => {
  try {
    const response = trashMailApi(mail);
    toast.success("Mail Moved Trash Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Mail Moved Trash Failed", { autoClose: 3000 });
    return error;
  }
});

export const labelMail = createAsyncThunk("mailbox/labelMail", async (mail) => {
  try {
    const data = labelMailApi(mail.forId);
    const response = await data;
    return { response: response, label: mail.e };
  } catch (error) {
    return error;
  }
});

export const getAllMails = createAsyncThunk("mailbox/getAllMails", async () => {
  try {
    const currentUserString = localStorage.getItem("currentUser");
    if (!currentUserString) {
      throw new Error("No currentUser found in localStorage");
    }

    const currentUser = JSON.parse(currentUserString);
    const token = currentUser.token;

    if (!token) {
      throw new Error("No token found in currentUser object");
    }

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/contact-us`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("inside get a instructor thunk --2", response);
    console.log("inside get a instructor thunk", response.data);
    return response.data?.contacts;
  } catch (error) {
    console.log("inside get a instructor thunk error", error.response.data);
    throw error.response.data.message;
  }
});
