// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { Navigate } from "react-router-dom";
// import { useProfile } from "../Common/Hooks/UserHooks";
// import { setAuthorization } from "../helpers/api_helper";
// import { logoutUser } from "../slices/thunk";

// const AuthProtected = (props) => {
//   const dispatch = useDispatch();
//   const { userProfile, loading, token } = useProfile();

//   useEffect(() => {
//     if (userProfile && !loading && token) {
//       setAuthorization(token);
//     } else if (!userProfile && loading && !token) {
//       dispatch(logoutUser());
//     }
//   }, [token, userProfile, loading, dispatch]);

//   /*
//       Navigate is un-auth access protected routes via url
//       */

//   if (!userProfile && loading && !token) {
//     return <Navigate to={{ pathname: "/login" }} />;
//   }

//   return <>{props.children}</>;
// };

// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { Navigate } from "react-router-dom";

// const AuthProtected = (props) => {
//   const loginSlice = useSelector((state) => state.Login);
//   const account = useSelector((state) => state.Account);

//   console.log("loginSlice user", loginSlice.user);
//   console.log("account user", account.user);

//   useEffect(() => {
//     if (!loginSlice.user) {
//       // Redirect to login if token or currentUser key is missing, or if permissions don't include "admin_dashboard"
//       return <Navigate to={{ pathname: "/auth-signin" }} />;
//     }
//   }, [loginSlice.user, account.user]);

//   // const currentUser = JSON.parse(localStorage.getItem("currentUser"));
//   // const currentUser = account.user || loginSlice.user;

//   return <>{props.children}</>;
// };

// export default AuthProtected;

// import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthProtected = (props) => {
  const loginSlice = useSelector((state) => state.Login);
  const account = useSelector((state) => state.Account);

  console.log("loginSlice user", loginSlice.user);
  console.log("account user", account.user);

  // Check if user is not logged in and redirect to login page
  if (!loginSlice?.user) {
    return <Navigate to="/auth-signin" />;
  }

  return <>{props.children}</>;
};

export default AuthProtected;
