import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllStudents = createAsyncThunk(
  "auth/getAllStudents",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/students`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("response in thunk", response);
      return response.data?.users?.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const toggleStudentStatus = createAsyncThunk(
  "auth/toggleStudentStatus",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/students/toggle-active-status`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response in thunk", response);
      return response.data?.users;
    } catch (error) {
      console.log("error in thunk", error);
      // throw error.response.data.message;
      throw error.response.data.message;
    }
  }
);
