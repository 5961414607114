import { createSlice } from "@reduxjs/toolkit";
import { getAllPendingInvitations, resendInvitation } from "./thunk";
// import { message } from "antd";

export const initialState = {
  allInvitations: [],
  allPendingInvitations: [],
  error: null,
  loading: false,
  resendSuccess: '',
  // selectedNews: null,
};

const invitations = createSlice({
  name: "pending-invitations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all instructor
    builder.addCase(getAllPendingInvitations.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllPendingInvitations.fulfilled, (state, action) => {
      state.loading = false;
      state.allPendingInvitations = action.payload;
    });
    builder.addCase(getAllPendingInvitations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //resend a pending invitations
    builder.addCase(resendInvitation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(resendInvitation.fulfilled, (state, action) => {
      state.loading = false;
      state.resendSuccess = action.payload
    });
    builder.addCase(resendInvitation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default invitations.reducer;
