import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAllInstructor,
  inviteAInstructor,
  toggleInstructorStatus,
} from "../../slices/thunk";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Modal,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import usecustomStyles from "../../Common/customStyles";
// import { Link } from "react-router-dom";
import { ChevronDown } from "lucide-react";
// import { recentOrders } from "../../Common/data";

import { UserAddOutlined } from "@ant-design/icons";
import useToast from "../../hooks/useToast";
import { useNavigate } from "react-router-dom";

const customStyles = usecustomStyles();
const { Text } = Typography;

function AllInstructor() {
  const instructorReduer = useSelector((state) => state.Instructor);
  console.log("instructor data", instructorReduer.allInstructorData);

  const showToast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllInstructor());
  }, [dispatch]);

  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setError("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleSubmit = () => {
    // Validate email
    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Your logic to submit the form
    // Here, you can dispatch an action or call an API to add the instructor
    // For demonstration, let's just log the email
    console.log("Adding instructor with email:", email);
    setIsLoading(true);
    dispatch(inviteAInstructor({ email }))
      .unwrap()
      .then(() => {
        showToast("success", "Invitaion sent successfully");
        setVisible(false);
        setEmail("");
        setError("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error in toast", error);
        showToast("error", error.message);
        setIsLoading(false);
      });
  };

  // ------------------------------------------------------------------------
  const [instructorStatus, setInstructorStatus] = useState({});

  // Assuming instructorReduer.allInstructorData is the array of instructors
  // Initialize instructorStatus state based on allInstructorData
  useEffect(() => {
    const initialStatus = {};
    instructorReduer?.allInstructorData?.forEach((instructor) => {
      initialStatus[instructor.id] = instructor.instructor.is_active;
    });
    setInstructorStatus(initialStatus);
  }, [instructorReduer.allInstructorData]);

  // Modify handleBlock function to update instructorStatus state
  // Modify handleBlock function to update loading state
  const handleBlock = (id) => {
    // Set loading state to true for the specific switch
    setLoading({ ...loading, [id]: true });

    // Toggle the status
    const updatedStatus = { ...instructorStatus };
    updatedStatus[id] = !updatedStatus[id];

    // Dispatch action or call API to update the instructor status
    dispatch(
      toggleInstructorStatus({ user_id: id, is_active: updatedStatus[id] })
    )
      .unwrap()
      .then(() => {
        // Success message
        showToast("success", "Instructor status updated successfully");
        setInstructorStatus(updatedStatus);
      })
      .catch((error) => {
        // Rollback the status change
        updatedStatus[id] = !updatedStatus[id];
        setInstructorStatus(updatedStatus);

        // Display error message
        setError(error.message || "Failed to update instructor status");
        showToast(
          "error",
          error.message || "Failed to update instructor status"
        );
      })
      .finally(() => {
        // Set loading state to false after the operation is complete
        setLoading({ ...loading, [id]: false });
      });
  };

  // ------------------------------------------------------------------------

  // const columns = [
  //   {
  //     title: "Name",
  //     sorter: (a, b) => a.name.localeCompare(b.name),
  //     sortDirections: ["ascend", "descend"],
  //     render: ({ profile_picture, name, email }) => {
  //       return (
  //         <span style={{}}>
  //           <div style={{ display: "flex", alignItems: "center" }}>
  //             <div>
  //               <img
  //                 src={profile_picture}
  //                 alt=""
  //                 style={{
  //                   borderRadius: "50%",
  //                   height: "32px",
  //                   width: "32px",
  //                   marginRight: "10px",
  //                 }}
  //               />
  //             </div>
  //             <div>
  //               <h6 style={{ fontSize: "13px" }}>
  //                 {name?.split("+").join(" ")}
  //               </h6>
  //               <div
  //                 style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
  //               >
  //                 {email}
  //               </div>
  //             </div>
  //           </div>
  //         </span>
  //       );
  //     },
  //   },
  //   {
  //     title: "Phone Number",
  //     dataIndex: "phone",
  //     align: "center",
  //   },
  //   {
  //     title: "Course Assigned",
  //     dataIndex: "instructor",
  //     align: "center",
  //     width: 400,
  //     render: (instructor) => (
  //       <div
  //         style={{
  //           maxWidth: "400px",
  //           whiteSpace: "normal",
  //           textAlign: "center",
  //         }}
  //       >
  //         {instructor?.courses?.map((course) => (
  //           <Tag key={course.title} style={{ marginBottom: "5px" }}>
  //             {course.title}
  //           </Tag>
  //         ))}
  //       </div>
  //     ),
  //   },

  //   {
  //     title: "Status",
  //     dataIndex: "id",
  //     align: "center",
  //     render: (id) => (
  //       <Tag color={instructorStatus[id] ? "green" : "red"}>
  //         {instructorStatus[id] ? "Active" : "Blocked"}
  //       </Tag>
  //     ),
  //   },
  //   {
  //     title: "Block",
  //     dataIndex: "id",
  //     align: "center",
  //     render: (id) => (
  //       <Switch
  //         checked={instructorStatus[id]}
  //         onClick={() => handleBlock(id)}
  //         size="small"
  //         loading={loading[id]}
  //       />
  //     ),
  //   },
  //   {
  //     title: "Action",
  //     align: "center",
  //     dataIndex: "id", // Assuming you have an 'id' field in your data
  //     render: (id) => (
  //       <Button
  //         type="primary"
  //         size="small"
  //         onClick={() => navigate(`/instructor/edit/${id}`)}
  //       >
  //         Edit
  //       </Button>
  //     ),
  //   },
  // ];

  const columns = [
    {
      // title: <div style={{ textAlign: "center" }}>Name</div>,
      title: <div>Name</div>,
      // align: "center",
      width: 300,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      render: ({ profile_picture, name, email }) => {
        return (
          <span style={{ display: "block" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div>
                <img
                  src={profile_picture}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div>
                <h6 style={{ fontSize: "13px" }}>
                  {name?.split("+").join(" ")}
                </h6>
                <div
                  style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
                >
                  {email}
                </div>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Phone Number</div>,
      dataIndex: "phone",
      align: "center",
    },
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "400px" }}>
          Course Assigned
        </div>
      ),
      dataIndex: "instructor",
      align: "center",
      width: 500,
      render: (instructor) => (
        <div
          style={{
            maxWidth: "400px",
            whiteSpace: "normal",
            textAlign: "center",
            margin: "0 auto",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {instructor?.courses?.map((course) => (
            <a
              key={course.title}
              href={`https://noe-talk.xyz/course/${course.id}`}
              target="_blank"
            >
              <Tag color="geekblue" style={{ marginBottom: "5px" }}>
                {course.title}
              </Tag>
            </a>
          ))}
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Status</div>,
      dataIndex: "id",
      align: "center",
      width: 200,
      render: (id) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Ensures the div takes the full height of the table cell
          }}
        >
          <Tag
            color={instructorStatus[id] ? "green" : "red"}
            style={{
              textAlign: "center",
              // width: "100%",
              margin: 0,
              padding: "2px 4px",
            }}
          >
            {instructorStatus[id] ? "Active" : "Blocked"}
          </Tag>
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Block</div>,
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <Switch
          checked={instructorStatus[id]}
          onClick={() => handleBlock(id)}
          size="small"
          loading={loading[id]}
        />
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Action</div>,
      align: "center",
      dataIndex: "id", // Assuming you have an 'id' field in your data
      render: (id) => (
        <Button
          type="primary"
          size="small"
          onClick={() => navigate(`/instructor/edit/${id}`)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const pagination = {
    pageSize: 5, // Set the number of items per page
    total: instructorReduer?.allInstructorData?.length, // Total number of items in your data source
  };

  const items = [
    { label: "Today", key: "0" },
    { label: "Yesterday", key: "1" },
    { label: "Last 7 Days", key: "2" },
    { label: "Last 30 Days", key: "3" },
    { label: "This Month", key: "4" },
    { label: "Last Month", key: "5" },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
        sorter,
        pageSize
      );
    },
  };

  return (
    <>
      <Button
        style={{ marginTop: "70px" }}
        type="primary"
        onClick={showModal}
        icon={<UserAddOutlined />}
      >
        Add Instructor
      </Button>
      <Modal
        title="Add Instructor"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            key="submit"
            type="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label="Email"
            validateStatus={error ? "error" : ""}
            help={error}
          >
            <Input
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter Email Adress"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Col xs={24} style={{ marginTop: "20px" }}>
        <Card className="recentCard">
          <div
            style={{
              display: "flex",
              padding: "22px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
            >
              All Instructor
            </h4>
            <div>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: customStyles.colorText }}
                >
                  <Space>
                    <Text style={{ fontSize: "12px", fontWeight: "500" }}>
                      SORT BY:{" "}
                    </Text>
                    <span
                      style={{
                        fontWeight: "400",
                        color: customStyles.textMuted,
                        fontSize: "12px",
                      }}
                    >
                      Today
                      <ChevronDown size={15} />
                    </span>
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>

          {/* Table */}
          <div
            style={{
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <Table
              columns={columns || []}
              dataSource={(instructorReduer.allInstructorData || []).map(
                (order, index) => ({
                  ...order,
                  key: index,
                })
              )}
              pagination={pagination}
              rowSelection={{
                ...rowSelection,
              }}
            />
          </div>
        </Card>
      </Col>
    </>
  );
}

export default AllInstructor;
