import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllPendingInvitations, resendInvitation } from "../../slices/thunk";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import usecustomStyles from "../../Common/customStyles"
import dayjs from "dayjs";
import "./invitations.css";
// import { Link } from "react-router-dom";
// import { ChevronDown } from "lucide-react";
// import { recentOrders } from "../../Common/data";

// import { UserAddOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
// import TextArea from "antd/es/input/TextArea";

const customStyles = usecustomStyles();
const { Text } = Typography;

function AllPendingInvitations() {
  const invitationReducer = useSelector((state) => state.Invitations);
  const { error, loading, resendSuccess } = useSelector((state) => state.Invitations);
  if(error) {
    message.error(error);
  }
  const [selectedMail, setSelectedMail] = useState();
  
  console.log("All pending invitation", invitationReducer);

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleResend = (email) => {
    console.log(email);
    setSelectedMail(email);
    dispatch(resendInvitation({email: email}))
  };

  useEffect(() => {
    dispatch(getAllPendingInvitations());
  }, [dispatch]);

  useEffect(()=>{
    if(!loading && resendSuccess !== '') {
      message.success(resendSuccess);
    }
  }, [loading])

  // ------------------------------------------------------------------------

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["ascend", "descend"],
      //   width: "25%", // Adjust the width as needed
      //   align: "center", // Aligning center
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   render: (description) => (
    //     <TextArea
    //       value={description}
    //       autoSize={{ minRows: 3, maxRows: 5 }}
    //       readOnly
    //     />
    //   ),
    // },
    {
      title: "Invitation URL",
      dataIndex: ["email", "token"],
      render: (email, record) => (
        <>
          <span>{record.email}&token={record.token.slice(0, 4)}... </span>
          <span>
            <Button className="invitation-copy" 
              onClick={(e)=>{
                console.log("check the record", `${record.email}&token=${record.token}`)
                navigator.clipboard.writeText(`${record.email}&token=${record.token}`);
                message.success('Invitation link copied!')
              }}
            >copy</Button>
          </span>
        </>
      ),
    },
    {
      title: "Last sent",
      dataIndex: "updated_at",
      sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        `${dayjs(record.updated_at).format('YYYY-M-D hh:mm')}`
      ),
    },
    {
      title: "Action",
      dataIndex: ["email", "token"],
      render: (email, record) => (
        <Space size="middle" align="center">
          <Popconfirm
            title="Resend invitation?"
            description="Are you sure to delete this course?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleResend(record.email)}
          >
            <Button size="small" type="primary" loading={loading && selectedMail === record.email}>
              Resend
            </Button>
          </Popconfirm>
        </Space>
      ),
      width: "20%", // Adjust the width as needed
      align: "center", // Aligning center
    },
  ];

  const pagination = {
    pageSize: 5, // Set the number of items per page
    total: invitationReducer?.allPendingInvitations?.data, // Total number of items in your data source
  };

  // const items = [
  //   { label: "Today", key: "0" },
  //   { label: "Yesterday", key: "1" },
  //   { label: "Last 7 Days", key: "2" },
  //   { label: "Last 30 Days", key: "3" },
  //   { label: "This Month", key: "4" },
  //   { label: "Last Month", key: "5" },
  // ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
        sorter,
        pageSize
      );
    },
  };

  return (
    <>
      <Col xs={24} style={{ marginTop: "20px" }}>
        <Card className="recentCard">
          <div
            style={{
              display: "flex",
              padding: "22px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
            >
              All Pending Invitations
            </h4>
            <div>
              {/* <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: customStyles.colorText }}
                >
                  <Space>
                    <Text style={{ fontSize: "12px", fontWeight: "500" }}>
                      SORT BY:{" "}
                    </Text>
                    <span
                      style={{
                        fontWeight: "400",
                        color: customStyles.textMuted,
                        fontSize: "12px",
                      }}
                    >
                      Today
                      <ChevronDown size={15} />
                    </span>
                  </Space>
                </a>
              </Dropdown> */}
            </div>
          </div>

          {/* Table */}
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            {
              invitationReducer?.allPendingInvitations?.data &&
              <Table
                columns={columns || []}
                dataSource={(invitationReducer?.allPendingInvitations?.data || []).map(
                  (order, index) => ({
                    ...order,
                    key: index,
                  })
                )}
                pagination={pagination}
                rowSelection={{
                  ...rowSelection,
                }}
                align="center"
              />
            }

          </div>
        </Card>
      </Col>
    </>
  );
}

export default AllPendingInvitations;
