import { message } from 'antd';

export default function imageValidityChecker(file, fileTypeList, maxSize, dimention = { height: null, width: null }) {

    const isLt1M = file.size / 1024 / 1024 < maxSize;
    const isJpgOrPng = fileTypeList.includes(file.type)

    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    if (!isLt1M) {
        message.error("Image must smaller than 1MB!");
    }
    console.log("check the file before abc", isLt1M, isJpgOrPng, file.size, file);
    if (!isJpgOrPng || !isLt1M) {
        return false;
    }

    return true;
}