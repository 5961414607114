import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

export const getAllCourses = createAsyncThunk(
  "auth/getAllCourses",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/courses`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("response.data in thunk", response.data);
      return response?.data?.courses;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const getCourseById = createAsyncThunk(
  "auth/getCourseById",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/courses/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("response.data in thunk", response.data);
      return response?.data?.course;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const getAllCourseCategories = createAsyncThunk(
  "auth/getAllCourseCategories",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/course-categories`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("response.data in thunk", response.data);
      return response?.data?.categories;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const addNewCourse = createAsyncThunk(
  "auth/addNewCourse",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/courses/store`,
        body,
        {
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            // Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data in thunk", response.data);
      if (response?.data?.course) {
        message.success("New Course Added Successfully");
      }
      return response?.data?.course;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const updateCourse = createAsyncThunk(
  "auth/updateCourse",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/courses/update`,
        body,
        {
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            // Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data in thunk", response.data);
      if (response?.data?.course) {
        message.success("Course Updated Successfully");
      }
      return response?.data?.course;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const deleteCourseById = createAsyncThunk(
  "auth/deleteCourseById",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/courses/delete`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data in thunk", response.data);
      if (response?.data?.deleted_course_id) {
        message.success("Course Deleted Successfully");
      }
      return response?.data?.deleted_course_id;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const addNewCourseCatogry = createAsyncThunk(
  "auth/addNewCourseCategory",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/course-category/store`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data in thunk", response.data);
      if (response?.data?.course_category) {
        message.success("New Course Added Successfully");
      }
      return response?.data?.course_category;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);