//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper";

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange,
} from "./reducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
export const registerUser = (user) => async (dispatch) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      response = fireBaseBackend.registerUser(user.email, user.password);
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = postJwtRegister("/post-jwt-register", user);
    } else if (process.env.REACT_APP_DEFAULTAUTH) {
      response = postFakeRegister(user);
      const data = await response;
      if (data.message === "success") {
        dispatch(registerUserSuccessful(data));
      } else {
        dispatch(registerUserFailed(data));
      }
    }
  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange("");
    return response;
  } catch (error) {
    return error;
  }
};

export const adminSignUp = createAsyncThunk(
  "auth/admin/signup",
  async (body) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/register`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("response", response);
      console.log("response..data", response.data);
      return response.data;
    } catch (error) {
      console.log("error in thunk", error);
      throw error?.response?.data;
    }
  }
);
