import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllNews } from "../../slices/thunk";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import usecustomStyles from "../../Common/customStyles";
// import { Link } from "react-router-dom";
import { ChevronDown } from "lucide-react";
// import { recentOrders } from "../../Common/data";

import { UserAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const customStyles = usecustomStyles();
const { Text } = Typography;

function AllNews() {
  const newsReducer = useSelector((state) => state.News);
  console.log("News", newsReducer?.allNewsData?.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (id) => {
    console.log(id);
  };

  useEffect(() => {
    dispatch(getAllNews());
  }, [dispatch]);

  // ------------------------------------------------------------------------

  const columns = [
    {
      title: "News Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["ascend", "descend"],
      //   width: "25%", // Adjust the width as needed
      //   align: "center", // Aligning center
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description) => (
        <TextArea
          value={description}
          autoSize={{ minRows: 3, maxRows: 5 }}
          readOnly
        />
      ),
    },
    {
      title: "Banner Image",
      dataIndex: "banner",
      render: (banner) => (
        <img
          src={banner}
          alt="banner"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Space size="middle" align="center">
          {" "}
          {/* Align center */}
          <Button
            size="small"
            type="primary"
            onClick={() => navigate(`/news/edit/${record.id}`)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Delete the course"
            description="Are you sure to delete this course?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button size="small" danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
      width: "20%", // Adjust the width as needed
      align: "center", // Aligning center
    },
  ];

  const pagination = {
    pageSize: 5, // Set the number of items per page
    total: newsReducer?.allNewsData?.data, // Total number of items in your data source
  };

  const items = [
    { label: "Today", key: "0" },
    { label: "Yesterday", key: "1" },
    { label: "Last 7 Days", key: "2" },
    { label: "Last 30 Days", key: "3" },
    { label: "This Month", key: "4" },
    { label: "Last Month", key: "5" },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
        sorter,
        pageSize
      );
    },
  };

  return (
    <>
      <Button
        style={{ marginTop: "70px" }}
        type="primary"
        // onClick={showModal}
        icon={<UserAddOutlined />}
      >
        Add News
      </Button>
      <Col xs={24} style={{ marginTop: "20px" }}>
        <Card className="recentCard">
          <div
            style={{
              display: "flex",
              padding: "22px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
            >
              All News
            </h4>
            <div>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: customStyles.colorText }}
                >
                  <Space>
                    <Text style={{ fontSize: "12px", fontWeight: "500" }}>
                      SORT BY:{" "}
                    </Text>
                    <span
                      style={{
                        fontWeight: "400",
                        color: customStyles.textMuted,
                        fontSize: "12px",
                      }}
                    >
                      Today
                      <ChevronDown size={15} />
                    </span>
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>

          {/* Table */}
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Table
              columns={columns || []}
              dataSource={(newsReducer?.allNewsData?.data || []).map(
                (order, index) => ({
                  ...order,
                  key: index,
                })
              )}
              pagination={pagination}
              rowSelection={{
                ...rowSelection,
              }}
              align="center"
            />
          </div>
        </Card>
      </Col>
    </>
  );
}

export default AllNews;
