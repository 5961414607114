// import { useCallback, useState } from "react";
// import { useDispatch } from "react-redux";

// export function useThunk(thunk) {
//   const [isloading, setIsloading] = useState(false);
//   const [error, setError] = useState(null);

//   const dispatch = useDispatch();

//   const runThunk = useCallback(
//     (arg) => {
//       setIsloading(true);
//       dispatch(thunk(arg)) //return automatic action
//         .unwrap()
//         .then(() => {
//           setIsloading(false);
//         })
//         .catch((error) => {
//           setIsloading(false);
//           setError(error);
//         });
//     },
//     [dispatch, thunk]
//   );

//   return [runThunk, isloading, error];
// }

import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import useToast from "./useToast";
import { useNavigate } from "react-router-dom";

export function useThunk(thunk, toastSuccess, navigateUrl) {
  const showToast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const runThunk = useCallback(
    (arg) => {
      setIsLoading(true);
      dispatch(thunk(arg))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          if (toastSuccess) {
            showToast("success", toastSuccess);
            if (navigateUrl) {
              navigate(navigateUrl);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
          showToast("error", err.message);
        });
    },
    [dispatch, thunk, toastSuccess, showToast]
  );

  return [runThunk, isLoading, error];
}
