import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllPendingInvitations = createAsyncThunk(
  "auth/getAllPendingInvitations",
  async ({ page, perPage, body } = {}) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      // let url = `${process.env.REACT_APP_BASE_URL}/api/instructor-invitations/allpending`;
      if (page !== undefined) {
        // url += `?page=${page}`;
        body = { ...body, page: page }
        if (perPage !== undefined) {
          // url += `&per_page=${perPage}`;
          body = { ...body, per_page: perPage }
        }
      } else if (perPage !== undefined) {
        // url += `?per_page=${perPage}`;
        body = { ...body, per_page: perPage }
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/instructor-invitations/pending`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data in thunk", response?.data);
      // if (response?.data?.allPendingInvitations) {
      //   message.success("Course Deleted Successfully");
      // }
      return response?.data?.allPendingInvitations;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const resendInvitation = createAsyncThunk(
  "auth/resendInvitation",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/instructor-invitations/resend`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data resend in thunk", response?.message);
      // if (response?.data?.allPendingInvitations) {
      //   message.success("Course Deleted Successfully");
      // }
      return response?.message;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
