import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllLesson = createAsyncThunk(
  "auth/getAllLesson",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/lesson/show`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response in thunk", response);
      return response.data?.lessons;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
