import { Upload, message } from "antd";
import imageValidityChecker from "../Util/UploadImage";
import ImgCrop from "antd-cropper-img";

const UploadImage = ({
  onUploadImageChange,
  passengerFileList,
  formItemKey,
  maxUploadCount,
  cropperRatio,
  modalWidth,
  // throwError,
}) => {
  // console.log("check the file before", passengerFileList);
  // const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    if (newFileList.length < passengerFileList.length) {
      return;
    }
    console.log("check the filelist change", newFileList, passengerFileList);
    let fileLen = newFileList.length;
    if (!fileLen) {
      // setFileList(newFileList);
      onUploadImageChange(newFileList, formItemKey);
    } else {
      const file = newFileList[fileLen - 1];
      const isLt1M = file.size / 1024 / 1024 < 1;
      console.log("check the file before abc", isLt1M, file.size, file);
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      if (!isLt1M) {
        message.error("Image must smaller than 1MB!");
      }
      if (!isJpgOrPng || !isLt1M) {
        return;
      } else {
        // setFileList(newFileList);
        onUploadImageChange(newFileList, formItemKey);
      }
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onRemove = (removedFile) => {
    console.log("check the remove", removedFile);
    const updatedFileList = passengerFileList.filter(
      (file) => file.uid != removedFile.uid
    );
    onUploadImageChange(updatedFileList, formItemKey);
    console.log("updated file list after remove", updatedFileList);
    // setFileList(() => []);
  };

  // useEffect(() => {
  //   setFileList(passengerFileList ? passengerFileList : []);
  // }, []);

  // useEffect(() => {
  //   console.log("check the image upload", fileList);
  //   onUploadImageChange(fileList, formItemKey);
  // }, [fileList]);
  const beforeUpload = (file, changeFileList, formItemKey, currentFileList) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const newFileList = [
        ...currentFileList,
        {
          file: file,
          originFileObj: file,
          url: reader.result,
          thumbUrl: reader.result,
        },
      ];
      const imageValidity = imageValidityChecker(
        file,
        ["image/jpeg", "image/png", "image/jpg"],
        1
      );
      if (newFileList.length < currentFileList.length) {
        return false;
      } else if (newFileList.length && imageValidity) {
        changeFileList(newFileList, formItemKey);
      }
    };
    return false;
  };

  return (
    <ImgCrop aspect={cropperRatio ? cropperRatio : 1} rotationSlider modalWidth={modalWidth} quality={0.8}>
      <Upload
        // action={`${import.meta.env.VITE_BASE_URL}/tempUpload`}
        listType="picture-card"
        fileList={passengerFileList ? passengerFileList : []}
        onChange={onChange}
        onPreview={onPreview}
        showUploadList={{showPreviewIcon: false}}
        beforeUpload={(file) =>
          beforeUpload(
            file,
            onUploadImageChange,
            formItemKey,
            passengerFileList
          )
        }
        onRemove={onRemove}
        // accept="image/png, image/jpeg, image/jpg"
      >
        {passengerFileList?.length < maxUploadCount && "+ Upload"}
        {/* Upload */}
      </Upload>
    </ImgCrop>
  );
};
export default UploadImage;
