import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import withRouter from "../../Common/withRouter";
// import { logout } from "../../slices/auth/login/reducer";
import { logoutAdmin } from "../../slices/thunk";
import { useThunk } from "../../hooks/useThunk";

const Logout = () => {
  // document.title = "Sign Out" + process.env.REACT_APP_PAGE_TITLE;
  document.title = "Sign Out";

  const [logoutAdminWithTokenInvaliated] = useThunk(
    logoutAdmin,
    "Logged Out Successfully"
  );

  const loginSlice = useSelector((state) => state.Login);
  console.log("loginSlice", loginSlice);

  useEffect(() => {
    logoutAdminWithTokenInvaliated();
  }, [logoutAdminWithTokenInvaliated]);

  if (loginSlice.isUserLogout) {
    return <Navigate to="/auth-signin" />;
  }

  return <React.Fragment></React.Fragment>;
};

export default withRouter(Logout);
