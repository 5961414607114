import { createSlice } from "@reduxjs/toolkit";
import { adminSignUp } from "./thunk";

export const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: JSON.parse(localStorage.getItem("currentUser")) || null,
  success: false,
  error: false,
  isUserLogout: true,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerUserSuccessful(state, action) {
      state.user = action.payload.user;
      state.loading = false;
      state.success = true;
      state.registrationError = null;
    },
    registerUserFailed(state, action) {
      state.user = null;
      state.loading = false;
      state.registrationError = action.payload;
      state.error = true;
    },
    resetRegisterFlagChange(state) {
      state.success = false;
      state.error = false;
    },
    apiErrorChange(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
    },
  },
  extraReducers: (builder) => {
    //admin sign up
    builder.addCase(adminSignUp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(adminSignUp.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
    });
    builder.addCase(adminSignUp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange,
} = registerSlice.actions;

export default registerSlice.reducer;
