import avatar from "../../assets/images/users/avatar-1.jpg";

// actions
import { Card, Col, Form, Input, Row, Button, Typography } from "antd";
import Breadcrumb from "../../Common/Breadcrumb";
import usecustomStyles from "../../Common/customStyles";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changePassword, getPersonalInfo } from "../../slices/thunk";
import { useThunk } from "../../hooks/useThunk";
import useToast from "../../hooks/useToast";
// import useToast from "../../hooks/useToast";

const customStyles = usecustomStyles();

const { Text } = Typography;

const ChangePassword = () => {
  const dispatch = useDispatch();

  const login = useSelector((state) => state.Login);

  console.log("login.personalInfo", login.personalInfo);

  const showToast = useToast();
  const [changepass, loading] = useThunk(
    changePassword,
    "Password updated successfully"
  );

  const handlePasswordChange = (values) => {
    // Handle form submission logic here
    console.log("handlePasswordChange:", values);
    const body = {
      current_password: values.oldPassword,
      new_password: values.newPassword,
      new_password_confirmation: values.confirmPassword,
    };
    if (values.oldPassword === values.newPassword) {
      showToast(
        "error",
        "Your new password must be different from previous used password."
      );
    } else {
      changepass(body);
    }
  };

  useEffect(() => {
    dispatch(getPersonalInfo());
  }, [dispatch]);

  return (
    <>
      <div>
        {/* Render Breadcrumb */}
        <Breadcrumb mainTitle="Lizant" pageTitle="User Profile" />

        <Row>
          <Col xxl={24}>
            <Card>
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src={avatar}
                    alt=""
                    style={{
                      height: "72px",
                      width: "72px",
                      borderRadius: "50%",
                      marginRight: "30px",
                    }}
                  />
                </div>
                <div>
                  <div>
                    <h5
                      style={{
                        fontWeight: "bold",
                        fontSize: customStyles.h5,
                        marginBottom: "6px",
                      }}
                    >
                      {/* {currentUser?.user?.name?.split("+").join(" ")} */}
                      {login.personalInfo?.user?.name?.split("+").join(" ")}
                    </h5>
                    <Text type="secondary" style={{ marginBottom: "0" }}>
                      {/* {currentUser?.user?.email} */}
                      {login.personalInfo?.user?.email}
                    </Text>
                  </div>
                  <Text type="secondary">Admin</Text>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        {/* admin password update */}
        <div style={{ marginTop: "24px", marginBottom: "24px" }}>
          <div style={{ display: "flex" }}>
            <Text
              style={{
                paddingBottom: "8px",
                display: "block",
                marginBottom: "16px",
              }}
            >
              Change Password
            </Text>
          </div>
          <Card>
            <Form
              onFinish={handlePasswordChange}
              // labelCol={{ span: 2 }}
              // labelAlign="left"

              initialValues={{
                current_password: "",
                new_password: "",
                new_password_confirmation: "",
              }}
            >
              <Form.Item
                name="oldPassword"
                label="Current Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Current Password",
                  },
                ]}
              >
                <Input.Password placeholder="Current Password" />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password",
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Give atleast '6 character', 'one special character' , 'one number'",
                  },
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Retype Password"
                dependencies={["newPassword"]} // Validate based on newPassword
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("New passwords do not match!");
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Retype New Password" />
              </Form.Item>
              <div style={{ marginTop: "30px" }} className="text-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: customStyles.colorDanger }}
                  loading={loading}
                >
                  Update Password
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
