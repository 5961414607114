import { createSlice } from "@reduxjs/toolkit";
import { getAllLesson } from "./thunk";

export const initialState = {
  allLessonData: [],
  error: null,
  loading: false,
};

const aiBot = createSlice({
  name: "aiBot",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all aiBot
    builder.addCase(getAllLesson.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllLesson.fulfilled, (state, action) => {
      state.loading = false;
      state.allLessonData = action.payload;
    });
    builder.addCase(getAllLesson.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default aiBot.reducer;
