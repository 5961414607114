import { createSlice } from "@reduxjs/toolkit";
import { getAllReservation } from "./thunk";

export const initialState = {
  allReservationData: [],
  error: null,
  loading: false,
};

const reservation = createSlice({
  name: "reservation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all reservation
    builder.addCase(getAllReservation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllReservation.fulfilled, (state, action) => {
      state.loading = false;
      state.allReservationData = action.payload;
    });
    builder.addCase(getAllReservation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default reservation.reducer;
