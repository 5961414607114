import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCourses,
  getCourseById,
  getAllCourseCategories,
  addNewCourse,
  updateCourse,
  deleteCourseById,
  addNewCourseCatogry,
} from "./thunk";
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

export const initialState = {
  allCourseData: [],
  allCourseCategories: [],
  error: null,
  loading: false,
  selectedCourse: null,
  fetchedCourse: {},
};

const course = createSlice({
  name: "course",
  initialState,
  reducers: {
    updateAllCourseData(state, action) {
      state.allCourseData = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get all course
    builder.addCase(getAllCourses.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllCourses.fulfilled, (state, action) => {
      state.loading = false;
      state.allCourseData = action.payload;
      state.fetchedCourse = {};
    });
    builder.addCase(getAllCourses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //get course by id
    builder.addCase(getCourseById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCourseById.fulfilled, (state, action) => {
      state.loading = false;
      state.fetchedCourse = action.payload;
    });
    builder.addCase(getCourseById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //get course categories
    builder.addCase(getAllCourseCategories.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllCourseCategories.fulfilled, (state, action) => {
      console.log("test action.payload for cate", action.payload);
      state.loading = false;
      state.allCourseCategories = action.payload;
    });
    builder.addCase(getAllCourseCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //create a new course
    builder.addCase(addNewCourse.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addNewCourse.fulfilled, (state, action) => {
      state.loading = false;
      state.fetchedCourse = action.payload;
    });
    builder.addCase(addNewCourse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //updated a course
    builder.addCase(updateCourse.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateCourse.fulfilled, (state, action) => {
      state.loading = false;
      state.fetchedCourse = action.payload;
    });
    builder.addCase(updateCourse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //delete a course
    builder.addCase(deleteCourseById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    // -----------------------------------------------------------------------------------
    // builder.addCase(deleteCourseById.fulfilled, (state, action) => {
    //   state.loading = false;
    //   console.log("deleted course id", action.payload);

    //   const filteredData = state.allCourseData.filter(
    //     (course) => course.id !== action.payload
    //   );
    //   state.allCourseData = filteredData;
    // });

    // -----------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------
    // builder.addCase(deleteCourseById.fulfilled, (state, action) => {
    //   state.loading = false;
    //   console.log("deleted course id", action.payload);
    //   console.log("state", state);
    //   console.log("state.allCourseData?.data", state.allCourseData?.data);

    //   console.log("state.allCourseData", state.allCourseData);

    //   const filtered = state.allCourseData.data.filter(
    //     (course) => course.id !== action.payload
    //   );
    //   console.log("filtered", filtered);
    //   console.log("filtered.length", filtered.length);
    //   state.allCourseData = [...filtered];
    // });
    // builder.addCase(deleteCourseById.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // });
    // -----------------------------------------------------------------------------------

    builder.addCase(deleteCourseById.fulfilled, (state, action) => {
      state.loading = false;

      // Debug logs (can be removed after debugging)
      console.log("deleted course id", action.payload);
      console.log("state", state);
      console.log("state.allCourseData?.data", state.allCourseData?.data);

      // Ensure state.allCourseData.data exists and is an array before filtering
      if (Array.isArray(state.allCourseData.data)) {
        const filtered = state.allCourseData.data.filter(
          (course) => course.id !== action.payload
        );
        state.allCourseData = {
          ...state.allCourseData,
          data: filtered,
        };
      } else {
        state.allCourseData.data = [];
      }

      // Debug logs (can be removed after debugging)
      console.log("state.allCourseData", state.allCourseData);
    });

    //create a new course category
    //create a new course
    builder.addCase(addNewCourseCatogry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addNewCourseCatogry.fulfilled, (state, action) => {
      state.loading = false;
      state.allCourseCategories = [...state.allCourseCategories, action.payload ];
    });
    builder.addCase(addNewCourseCatogry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });


  },
});

export const { updateAllCourseData } = course.actions;

export default course.reducer;
