// import React, { useState, useEffect } from "react";
// import { isEmpty } from "lodash";

// // Formik Validation
// import * as Yup from "yup";
// import { useFormik } from "formik";

// //redux
// import { useSelector, useDispatch } from "react-redux";

// //Import Breadcrumb

// import avatar from "../../assets/images/users/avatar-1.jpg";

// // actions
// import { editProfile, resetProfileFlag } from "../../slices/thunk";
// import { createSelector } from "reselect";
// import { Alert, Card, Col, Form, Input, Row, Button, Typography } from "antd";
// import Breadcrumb from "../../Common/Breadcrumb";
// import usecustomStyles from "../../Common/customStyles";
// import withRouter from "../../Common/withRouter";

// const customStyles = usecustomStyles();

// const { Text } = Typography;

// const UserProfile = () => {
//   // page title
//   //   document.title = "User Profile" + process.env.REACT_APP_PAGE_TITLE;
//   document.title = "User Profile";

//   const dispatch = useDispatch();

//   const [email, setemail] = useState("admin@gmail.com");
//   const [name, setname] = useState("Admin");
//   const [idx, setidx] = useState(1);

//   const selectProfile = createSelector(
//     (state) => state.Profile,
//     (profile) => ({
//       user: profile.user,
//       success: profile.success,
//       error: profile.error,
//     })
//   );

//   const { user, success, error } = useSelector(selectProfile);

//   useEffect(() => {
//     if (localStorage.getItem("authUser")) {
//       const obj = JSON.parse(localStorage.getItem("authUser") || "{}");

//       if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//         obj.displayName = user.username;
//         setname(obj.displayName || "Admin");
//         setemail(obj.email);
//         setidx(obj.uid);
//       } else if (
//         process.env.REACT_APP_DEFAULTAUTH === "fake" ||
//         process.env.REACT_APP_DEFAULTAUTH === "jwt"
//       ) {
//         if (!isEmpty(user)) {
//           obj.data.displayName = user.username;
//           localStorage.removeItem("authUser");
//           localStorage.setItem("authUser", JSON.stringify(obj));
//         }
//         // setname(obj.data.displayName ? obj.data.displayName : "Admin");
//         setname(user?.username || "Admin");
//         setemail(obj.email || obj.data.email);
//         setidx(obj.uid || obj.data._id);
//       }

//       setTimeout(() => {
//         dispatch(resetProfileFlag());
//       }, 3000);
//     }
//   }, [dispatch, user]);
//   const validation = useFormik({
//     // enableReinitialize : use this flag when initial values needs to be changed
//     enableReinitialize: true,

//     initialValues: {
//       username: name || "Admin",
//       idx: idx || "",
//     },
//     validationSchema: Yup.object({
//       username: Yup.string().required("Please Enter Your UserName"),
//     }),
//     onSubmit: (values) => {
//       dispatch(editProfile(values));
//     },
//   });

//   return (
//     <React.Fragment>
//       <div>
//         {/* Render Breadcrumb */}
//         <Breadcrumb mainTitle="Lizant" pageTitle="User Profile" />

//         <Row>
//           <Col xxl={24}>
//             {error && error ? (
//               <Alert type="error" message={error}></Alert>
//             ) : null}
//             {success ? (
//               <Alert
//                 type="success"
//                 message={`Username Updated To ${name}`}
//               ></Alert>
//             ) : null}

//             <Card>
//               <div style={{ display: "flex" }}>
//                 <div>
//                   <img
//                     src={avatar}
//                     alt=""
//                     style={{
//                       height: "72px",
//                       width: "72px",
//                       borderRadius: "50%",
//                       marginRight: "30px",
//                     }}
//                   />
//                 </div>
//                 <div>
//                   <div>
//                     <h5
//                       style={{
//                         fontWeight: "bold",
//                         fontSize: customStyles.h5,
//                         marginBottom: "6px",
//                       }}
//                     >
//                       {name}
//                     </h5>
//                     <Text type="secondary" style={{ marginBottom: "0" }}>
//                       {email}
//                     </Text>
//                   </div>
//                   <Text type="secondary">Id no: #{idx}</Text>
//                 </div>
//               </div>
//             </Card>
//           </Col>
//         </Row>

//         <div style={{ marginTop: "24px" }}>
//           <Text
//             style={{
//               PaddingBottom: "8px",
//               display: "block",
//               marginBottom: customStyles.marginSM,
//             }}
//           >
//             Change User Name
//           </Text>

//           <Card>
//             <Form
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 validation.handleSubmit();
//                 return false;
//               }}
//             >
//               <div className="form-group">
//                 <label style={{ display: "block", marginBottom: "4px" }}>
//                   User Name
//                 </label>
//                 <Input
//                   name="username"
//                   placeholder="Enter User Name"
//                   style={{ boxShadow: "none" }}
//                   type="text"
//                   onChange={validation.handleChange}
//                   onBlur={validation.handleBlur}
//                   value={validation.values.username || ""}
//                   onInvalid={
//                     validation.touched.username && validation.errors.username
//                       ? validation.touched.username
//                       : undefined
//                   }
//                   status={
//                     validation.touched.username && validation.errors.username
//                       ? "error"
//                       : true
//                   }
//                 />
//                 {validation.touched.username && validation.errors.username && (
//                   <p style={{ color: customStyles.colorDanger }}>
//                     {validation.errors.username}
//                   </p>
//                 )}
//               </div>
//               <div style={{ marginTop: "30px" }} className="text-center">
//                 <Button
//                   htmlType="submit"
//                   style={{
//                     backgroundColor: customStyles.colorDanger,
//                     color: "white",
//                   }}
//                   onClick={(e) => {
//                     e.preventDefault();
//                     validation.handleSubmit();
//                     return false;
//                   }}
//                 >
//                   Update User Name
//                 </Button>
//               </div>
//             </Form>
//           </Card>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default withRouter(UserProfile);

//Import Breadcrumb

// ---------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------

// import avatar from "../../assets/images/users/avatar-1.jpg";

// // actions
// import { Card, Col, Form, Input, Row, Button, Typography } from "antd";
// import Breadcrumb from "../../Common/Breadcrumb";
// import usecustomStyles from "../../Common/customStyles";
// import withRouter from "../../Common/withRouter";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { getPersonalInfo, updatePersonalInfo } from "../../slices/thunk";
// import { useThunk } from "../../hooks/useThunk";
// // import useToast from "../../hooks/useToast";

// const customStyles = usecustomStyles();

// const { Text } = Typography;

// const UserProfile = () => {
//   // page title
//   // const currentUser = JSON.parse(localStorage.getItem("currentUser"));

//   const dispatch = useDispatch();
//   // const showToast = useToast();
//   const [updateProfile, loading] = useThunk(
//     updatePersonalInfo,
//     "Profile updated successfully"
//   );

//   const login = useSelector((state) => state.Login);
//   // const account = useSelector((state) => state.Account);

//   // let currentUser =
//   //   login.user ||
//   //   account.user ||
//   //   JSON.parse(localStorage.getItem("currentUser"));

//   console.log("login.personalInfo", login.personalInfo);

//   const handleSubmit = (values) => {
//     // Handle form submission logic here
//     console.log("Form values:", values);
//     const body = {
//       name: values.firstName + "+" + values.lastName,
//       phone: values.phone,
//     };
//     console.log(body);
//     updateProfile(body);
//   };

//   useEffect(() => {
//     dispatch(getPersonalInfo());
//   }, [dispatch]);

//   return (
//     <>
//       <div>
//         {/* Render Breadcrumb */}
//         <Breadcrumb mainTitle="Lizant" pageTitle="User Profile" />

//         <Row>
//           <Col xxl={24}>
//             <Card>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//               >
//                 <div>
//                   <img
//                     src={avatar}
//                     alt=""
//                     style={{
//                       height: "140px",
//                       width: "140px",
//                       borderRadius: "50%",
//                       marginRight: "30px",
//                     }}
//                   />
//                 </div>
//                 <div>
//                   <div>
//                     <h5
//                       style={{
//                         fontWeight: "bold",
//                         fontSize: customStyles.h5,
//                         marginBottom: "6px",
//                       }}
//                     >
//                       {/* {currentUser?.user?.name?.split("+").join(" ")} */}
//                       {login.personalInfo?.user?.name?.split("+").join(" ")}
//                     </h5>
//                     <Text type="secondary" style={{ marginBottom: "0" }}>
//                       {/* {currentUser?.user?.email} */}
//                       {login.personalInfo?.user?.email}
//                     </Text>
//                   </div>
//                   <Text type="secondary">Admin</Text>
//                 </div>
//               </div>
//             </Card>
//           </Col>
//         </Row>

//         {/* admin prodfie update */}
//         <div style={{ marginTop: "24px" }}>
//           <Text
//             style={{
//               PaddingBottom: "8px",
//               display: "block",
//               marginBottom: customStyles.marginSM,
//             }}
//           >
//             Update Profile
//           </Text>

//           <Card key={login.personalInfo === null ? 1 : 2}>
//             <Form onFinish={handleSubmit}>
//               <Row gutter={[16, 16]}>
//                 <Col xs={24} sm={24}>
//                   <div>
//                     <label style={{ marginBottom: "4px", display: "block" }}>
//                       First Name{" "}
//                       <span style={{ color: customStyles.colorDanger }}>*</span>
//                     </label>
//                     <Form.Item
//                       initialValue={
//                         login.personalInfo?.user?.name?.split("+")[0]
//                       }
//                       name="firstName"
//                       rules={[
//                         {
//                           required: true,
//                           message: "Please enter your First Name",
//                         },
//                       ]}
//                     >
//                       <Input
//                         placeholder="First Name"
//                         style={{ outline: "none", boxShadow: "none" }}
//                       />
//                     </Form.Item>
//                   </div>
//                 </Col>
//                 <Col xs={24} sm={24}>
//                   <div>
//                     <label style={{ marginBottom: "4px", display: "block" }}>
//                       Last Name{" "}
//                       <span style={{ color: customStyles.colorDanger }}>*</span>
//                     </label>
//                     <Form.Item
//                       initialValue={
//                         login.personalInfo?.user?.name?.split("+")[1]
//                       }
//                       name="lastName"
//                       rules={[
//                         {
//                           required: true,
//                           message: "Please enter your Last Name",
//                         },
//                       ]}
//                     >
//                       <Input
//                         placeholder="Last Name"
//                         style={{ outline: "none", boxShadow: "none" }}
//                       />
//                     </Form.Item>
//                   </div>
//                 </Col>
//               </Row>

//               <Row gutter={[16, 16]}>
//                 <Col xs={24} sm={24}>
//                   <div>
//                     <label style={{ marginBottom: "4px", display: "block" }}>
//                       Phone{" "}
//                       <span style={{ color: customStyles.colorDanger }}>*</span>
//                     </label>
//                     <Form.Item
//                       initialValue={login.personalInfo?.user?.phone}
//                       name="phone"
//                       rules={[
//                         {
//                           required: true,
//                           message: "Please enter your Phone Number",
//                         },
//                         {
//                           pattern: /^(0\d{10})$/,
//                           message:
//                             "Phone number must start with zero and be exactly 11 digits long",
//                         },
//                       ]}
//                     >
//                       <Input
//                         placeholder="Phone"
//                         style={{ outline: "none", boxShadow: "none" }}
//                       />
//                     </Form.Item>
//                   </div>
//                 </Col>
//               </Row>

//               <div style={{ marginTop: "30px" }} className="text-center">
//                 <Button
//                   type="primary"
//                   htmlType="submit"
//                   style={{ backgroundColor: customStyles.colorDanger }}
//                   loading={loading}
//                 >
//                   Update User Info
//                 </Button>
//               </div>
//             </Form>
//           </Card>
//         </div>
//       </div>
//     </>
//   );
// };

// export default withRouter(UserProfile);

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

// import avatar from "../../assets/images/users/avatar-1.jpg";

// actions
import { Card, Col, Form, Input, Row, Button, Typography } from "antd";
import Breadcrumb from "../../Common/Breadcrumb";
import usecustomStyles from "../../Common/customStyles";
import withRouter from "../../Common/withRouter";
import { useSelector } from "react-redux";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { getPersonalInfo, updatePersonalInfo } from "../../slices/thunk";
import { useThunk } from "../../hooks/useThunk";
import CropperImage from "antd-cropper-img";
const customStyles = usecustomStyles();

const { Text } = Typography;

// ----------------------------------------------------------------
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
// ----------------------------------------------------------------

const UserProfile = () => {
  // ------------------------------------------------------------------------------------------------
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const login = useSelector((state) => state.Login);

  console.log("login.personalInfo", login.personalInfo);

  const [imageFile, setImageFile] = useState(null);

  console.log("fileList", fileList);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setImageFile(newFileList[0]?.originFileObj);
  };
  useEffect(() => {
    if (login.personalInfo) {
      setPreviewImage(login.personalInfo?.user?.profile_picture);
    }
  }, [login.personalInfo]);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  useEffect(() => {
    if (login.personalInfo) {
      setImageFile([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: login.personalInfo?.user?.profile_picture,
          // originFileObj: login.personalInfo?.user?.profile_picture,
        },
      ]);
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: login.personalInfo?.user?.profile_picture,
          // originFileObj: login.personalInfo?.user?.profile_picture,
        },
      ]);
    } else {
      setImageFile(null);
    }
  }, [login.personalInfo]);

  console.log("fileList", fileList);
  // ------------------------------------------------------------------------------------------------

  // const dispatch = useDispatch();
  const [updateProfile, loading] = useThunk(
    updatePersonalInfo,
    "Profile updated successfully"
  );

  // const login = useSelector((state) => state.Login);

  // console.log("login.personalInfo", login.personalInfo);

  const handleSubmit = (values) => {
    // Handle form submission logic here
    console.log("Form values:", values);
    const formBody = new FormData();
    const body = {
      name: values.firstName + "+" + values.lastName,
      phone: values.phone,
    };
    console.log(body);

    Object.keys(body).forEach((key) => {
      console.log("check the key val", key, body[key]);
      formBody.append(key, body[key]);
    });

    if (imageFile && typeof imageFile === "object") {
      formBody.append("profile_picture", imageFile);
    } else if (imageFile && typeof imageFile === "string") {
      formBody.append("profile_picture_url", imageFile);
    } else {
      formBody.append("profile_picture_url", null);
    }

    // Log the FormData key-value pairs
    for (let [key, value] of formBody.entries()) {
      console.log("key values", key, value);
    }

    // updateProfile(body);
    updateProfile(formBody);
  };

  const [personalInformation] = useThunk(getPersonalInfo);

  useEffect(() => {
    // dispatch(getPersonalInfo());
    personalInformation();
  }, [personalInformation]);

  return (
    <>
      <div>
        {/* Render Breadcrumb */}
        <Breadcrumb mainTitle="Lizant" pageTitle="User Profile" />

        <Row>
          <Col xxl={24}>
            <Card>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <div>
                  <img
                    src={avatar}
                    alt=""
                    style={{
                      height: "140px",
                      width: "140px",
                      borderRadius: "50%",
                      marginRight: "30px",
                    }}
                  />
                </div> */}

                {/* ------------------------------------------------------------------------------------- */}
                <>
                  <CropperImage>
                    <Upload
                      listType="picture-circle"
                      onPreview={handlePreview}
                      onChange={handleChange}
                      onRemove={() => {
                        setImageFile(null);
                        setFileList([]);
                      }}
                      fileList={fileList.length ? fileList : imageFile}
                    >
                      {fileList.length > 0 && imageFile ? null : uploadButton}
                    </Upload>
                  </CropperImage>
                  {previewImage && (
                    <Image
                      wrapperStyle={{
                        display: "none",
                      }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  )}
                </>
                {/* ------------------------------------------------------------------------------------- */}
                <div>
                  <div>
                    <h5
                      style={{
                        fontWeight: "bold",
                        fontSize: customStyles.h5,
                        marginBottom: "6px",
                      }}
                    >
                      {/* {currentUser?.user?.name?.split("+").join(" ")} */}
                      {login.personalInfo?.user?.name?.split("+").join(" ")}
                    </h5>
                    <Text type="secondary" style={{ marginBottom: "0" }}>
                      {/* {currentUser?.user?.email} */}
                      {login.personalInfo?.user?.email}
                    </Text>
                  </div>
                  <Text type="secondary">Admin</Text>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        {/* admin prodfie update */}
        <div style={{ marginTop: "24px" }}>
          <Text
            style={{
              PaddingBottom: "8px",
              display: "block",
              marginBottom: customStyles.marginSM,
            }}
          >
            Update Profile
          </Text>

          <Card key={login.personalInfo === null ? 1 : 2}>
            <Form onFinish={handleSubmit}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24}>
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      First Name{" "}
                      <span style={{ color: customStyles.colorDanger }}>*</span>
                    </label>
                    <Form.Item
                      initialValue={
                        login.personalInfo?.user?.name?.split("+")[0]
                      }
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your First Name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="First Name"
                        style={{ outline: "none", boxShadow: "none" }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={24}>
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      Last Name{" "}
                      <span style={{ color: customStyles.colorDanger }}>*</span>
                    </label>
                    <Form.Item
                      initialValue={
                        login.personalInfo?.user?.name?.split("+")[1]
                      }
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Last Name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Last Name"
                        style={{ outline: "none", boxShadow: "none" }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24}>
                  <div>
                    <label style={{ marginBottom: "4px", display: "block" }}>
                      Phone{" "}
                      <span style={{ color: customStyles.colorDanger }}>*</span>
                    </label>
                    <Form.Item
                      initialValue={login.personalInfo?.user?.phone}
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Phone Number",
                        },
                        {
                          pattern: /^(0\d{10})$/,
                          message:
                            "Phone number must start with zero and be exactly 11 digits long",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Phone"
                        style={{ outline: "none", boxShadow: "none" }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <div style={{ marginTop: "30px" }} className="text-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: customStyles.colorDanger }}
                  loading={loading}
                >
                  Update User Info
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default withRouter(UserProfile);

// ----------------------------------------------------------------
// ----------------------------------------------------------------
