import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllCourses, deleteCourseById } from "../../slices/thunk";
import { FaLink } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";
import { FaStar } from "react-icons/fa";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import Spinners from "../../Common/Spinner";
import usecustomStyles from "../../Common/customStyles";
// import { Link } from "react-router-dom";
// import { ChevronDown } from "lucide-react";
// import { recentOrders } from "../../Common/data";

import { UserAddOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
// import { updateAllCourseData } from "../../slices/course/reducer";

const customStyles = usecustomStyles();
const { Text } = Typography;

function AllCourse() {
  const courseReducer = useSelector((state) => state.Course);
  console.log("courses", courseReducer?.allCourseData?.data);
  const isLoading = useSelector((state) => state?.Course?.loading);
  const error = useSelector((state) => state?.Course?.error);
  if (error) {
    console.log("check the error", error);
    message.error(error.message);
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (id) => {
    // console.log(id);
    if (id) {
      const body = { course_id: id };
      dispatch(deleteCourseById(body));
      // .unwrap()
      // .then(() => {
      //   let duplicate = courseReducer.allCourseData;
      //   const filtered = duplicate.data.filter((course) => course.id !== id);
      //   duplicate.data = filtered;

      //   dispatch(updateAllCourseData(duplicate));
      // });
    }
  };

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     // navigate(`/course/all`);
  //     dispatch(getAllCourses());
  //   }
  // }, [isLoading])

  // useEffect(()=>{
  //   // dispatch(getAllCourses());
  // }, [courseReducer])

  // ------------------------------------------------------------------------

  const columns = [
    {
      title: "Course Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["ascend", "descend"],
      //   width: "25%", // Adjust the width as needed
      //   align: "center", // Aligning center
    },
    // {
    //   title: "Category",
    //   dataIndex: "category",
    //   sorter: (a, b) => a.category.label.localeCompare(b.category.label),
    //   sortDirections: ["ascend", "descend"],
    //   render: (category) => category?.label,
    //   //   width: "25%", // Adjust the width as needed
    //   //   align: "center", // Aligning center
    // },
    {
      title: "Top Rated",
      dataIndex: "is_top_rated",
      render: (isTopRated) => (
        <div>
          {isTopRated ? (
            <FaStar style={{ fontSize: "24px", color: "#FFD700" }} />
          ) : (
            ""
          )}
        </div>
      ),
      sorter: (a, b) => a.is_top_rated - b.is_top_rated,
      //   width: "25%", // Adjust the width as needed
      //   align: "center", // Aligning center
    },
    {
      title: "Banner Image",
      dataIndex: "banner_url",
      render: (banner_url) => (
        <img
          src={banner_url}
          alt="banner"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Space size="middle" align="center">
          {" "}
          {/* Align center */}
          <Button
            // type="primary"
            onClick={() => navigate(`/course/edit/${record.id}`)}
            icon={<FiEdit size={24} color="blue" />}
          >
            {/* Edit */}
          </Button>
          <Popconfirm
            title="Delete the course"
            description="Are you sure to delete this course?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button
              className="border-hidden"
              icon={<TiDelete size={24} color="red" />}
            ></Button>
          </Popconfirm>
          <Link
            to={`${process.env.REACT_APP_MAIN_SITE_URL}/course/${record.id}`}
            target="_blank"
            className="border-hidden"
          >
            <FaLink size={18} color="blue" />
          </Link>
        </Space>
      ),
      width: "20%", // Adjust the width as needed
      align: "center", // Aligning center
    },
  ];

  const pagination = {
    pageSize: 5, // Set the number of items per page
    total: courseReducer?.allCourseData?.data, // Total number of items in your data source
  };

  const items = [
    { label: "Today", key: "0" },
    { label: "Yesterday", key: "1" },
    { label: "Last 7 Days", key: "2" },
    { label: "Last 30 Days", key: "3" },
    { label: "This Month", key: "4" },
    { label: "Last Month", key: "5" },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
        sorter,
        pageSize
      );
    },
  };

  return (
    <>
      {isLoading && <Spinners />}
      <Button
        style={{ marginTop: "70px" }}
        type="primary"
        onClick={() => {
          navigate("/course/add");
        }}
        icon={<UserAddOutlined />}
      >
        Add Course
      </Button>
      <Col xs={24} style={{ marginTop: "20px" }}>
        <Card className="recentCard">
          <div
            style={{
              display: "flex",
              padding: "22px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
            >
              All Courses
            </h4>
            <div>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: customStyles.colorText }}
                >
                  <Space>
                    <Text style={{ fontSize: "12px", fontWeight: "500" }}>
                      {/* SORT BY:{" "} */}
                    </Text>
                    <span
                      style={{
                        fontWeight: "400",
                        color: customStyles.textMuted,
                        fontSize: "12px",
                      }}
                    >
                      {/* Today */}
                      {/* <ChevronDown size={15} /> */}
                    </span>
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>

          {/* Table */}
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Table
              columns={columns || []}
              dataSource={(courseReducer?.allCourseData?.data || []).map(
                (order, index) => ({
                  ...order,
                  key: index,
                })
              )}
              pagination={pagination}
              rowSelection={{
                ...rowSelection,
              }}
              align="center"
            />
          </div>
        </Card>
      </Col>
    </>
  );
}

export default AllCourse;
