import { createSlice } from "@reduxjs/toolkit";
import { getAllNews, getNewsById, updateNews } from "./thunk";

export const initialState = {
  allNewsData: [],
  error: null,
  loading: false,
  selectedNews: null,
  fetchedNews: null,
};

const news = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all news
    builder.addCase(getAllNews.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllNews.fulfilled, (state, action) => {
      state.loading = false;
      state.allNewsData = action.payload;
    });
    builder.addCase(getAllNews.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //get a news by ID
    builder.addCase(getNewsById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getNewsById.fulfilled, (state, action) => {
      state.loading = false;
      state.fetchedNews = action.payload;
    });
    builder.addCase(getNewsById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    //update a news
    builder.addCase(updateNews.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateNews.fulfilled, (state, action) => {
      console.log("check the value of update", action.payload);
      state.loading = false;
      state.fetchedNews = action.payload;
    });
    builder.addCase(updateNews.rejected, (state, action) => {
      console.log("check the value of updatea reject", action);
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default news.reducer;
