import { useState, useEffect } from "react";
import { Card, Form, Button, Typography } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Text } = Typography;

const TermsAndPrivacy = () => {
  const [form] = Form.useForm();

  const [editorValue, setEditorValue] = useState({
    privacyPolicy: "",
    termsConditions: "",
  });

  const handleSubmit = () => {
    const body = {
      ...form.getFieldsValue(),
      privacyPolicy: editorValue.privacyPolicy,
      termsConditions: editorValue.termsConditions,
    };
    console.log("Form Data Body:", body);
  };

  useEffect(() => {
    // Fetch and set initial values if needed
    form.setFieldValue("privacyPolicy", editorValue.privacyPolicy);
    form.setFieldValue("termsConditions", editorValue.termsConditions);
  }, [editorValue]);

  return (
    <div style={{ marginTop: "50px" }}>
      <Text
        style={{
          paddingBottom: "8px",
          display: "block",
          marginBottom: "16px",
        }}
      >
        Terms and Privacy
      </Text>
      <Card
        style={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            privacyPolicy: editorValue.privacyPolicy,
            termsConditions: editorValue.termsConditions,
          }}
        >
          <Form.Item
            noStyle
            label="Privacy Policy"
            name="privacyPolicy"
          ></Form.Item>
          <Form.Item label="Privacy Policy">
            <ReactQuill
              theme="snow"
              defaultValue={editorValue.privacyPolicy}
              onChange={(content, delta, source, editor) => {
                setEditorValue({
                  ...editorValue,
                  privacyPolicy: editor.getHTML().replace("<p><br></p>", ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            noStyle
            label="Terms & Condition"
            name="termsConditions"
          ></Form.Item>
          <Form.Item label="Terms & Condition">
            <ReactQuill
              theme="snow"
              defaultValue={editorValue.termsConditions}
              onChange={(content, delta, source, editor) => {
                setEditorValue({
                  ...editorValue,
                  termsConditions: editor.getHTML().replace("<p><br></p>", ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default TermsAndPrivacy;
