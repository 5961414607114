import { useState, useEffect } from "react";
import { Card, Form, Input, Button, Row, Col, Upload, Typography, message } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import usecustomStyles from "../../Common/customStyles";
import UploadImage from "../../Common/UploadImage";
import { useNavigate, useParams } from "react-router-dom";
import {
  getNewsById, updateNews,
} from "../../slices/thunk";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { useThunk } from "../../hooks/useThunk";

const customStyles = usecustomStyles();
const { TextArea } = Input;
const { Text } = Typography;

const EditNews = () => {
  const { id } = useParams();
  const currentUser = useSelector((state) => state?.Login?.user);
  const newsData = useSelector((state) => state?.News?.fetchedNews);
  // const courseCategoriesData = useSelector(
  //   (state) => state?.Course?.allCourseCategories
  // );
  const isLoading = useSelector((state) => state?.News?.loading);
  const error = useSelector((state) => state?.News?.error);
  // const [courseData, setCourseDatat] = useState({});
  // const [formData, setFormData] = useState({
  //   title: "",
  //   description: "",
  //   content: "",
  //   requirements: "",
  //   banner: "",
  //   is_top_rated: false,
  //   category: "",
  // });

  console.log("newsData fetched", newsData);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [bannerImage, setBannerImage] = useState([]);
  const [editorValue, setEditorValue] = useState({
    description: "",
  });
  if (error) {
    console.log("check the error", error);
    message.error(error.message);
  }

  const validateCustom = (fieldNames, formObject) => {
    const formValues = formObject.getFieldsValue([...fieldNames]);
    const messagesList = fieldNames
      .map((fieldName) =>
        formValues[fieldName]?.length ? "" : `${fieldName} is required`
      )
      .filter((message) => message !== "");
    console.log("check the list of message", messagesList);
    if (messagesList.length) {
      // message.error(`<p>${messagesList.join('<br>')}</p>`);
      messagesList.map((messageVal) => {
        message.error(messageVal);
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log("check the env", process.env.REACT_APP_BASE_URL);
    dispatch(getNewsById(id));
    // dispatch(getAllCourseCategories());
  }, [id]);
  useEffect(() => {
    console.log("check the courseData", newsData);
    if (newsData) {
      if (Object.keys(newsData).length) {
        // setEditorValue({
        //   content: courseData.content ? courseData.content : "",
        //   requirements: courseData.requirements ? courseData.requirements : "",
        //   description: courseData.description ? courseData.description : "",
        // });
        setBannerImage([
          {
            uid: "-1",
            status: "done",
            url: newsData.banner,
          },
        ]);
        setEditorValue({
          description: newsData?.description
        })
      }
    }
  }, [newsData]);
  useEffect(() => {
    form.setFieldValue('description', editorValue.description)
  }, [editorValue]);
  // const [formData, setFormData] = useState({
  //   title: "",
  //   description: "",
  //   banner: "",
  // });

  const navigate = useNavigate();

  // const handleInputChange = (e, field) => {
  //   setFormData({ ...formData, [field]: e.target.value });
  // };

  // const handleBannerChange = (info) => {
  //   if (info.file.status === "done") {
  //     const file = info.file.originFileObj;
  //     // setFormData({ ...formData, banner: file }); // Update the banner state with the selected file object
  //   }
  // };

  const handleSubmit = () => {
    // Implement form submission logic
    console.log("Form Data:", form.getFieldsValue());
    const body = {
      ...form.getFieldsValue(),
      news_id: id,
      // course_category_id: form.getFieldValue("category").id,
      // content: JSON.stringify(form.getFieldValue("content")),
      // requirements: JSON.stringify(form.getFieldValue("requirements")),
    };
    const formData = new FormData();

    if (validateCustom(["description"], form)) {
      Object.keys(body).forEach(async (key) => {
        // console.log('visa values: ', data[key])
        if (key != 'banner') {
          formData.append(`${key}`, body[key]);
        }
      });
      if (bannerImage.length > 0) {
        if (bannerImage[0]?.originFileObj) {
          const ext = bannerImage[0].originFileObj.name.slice(
            bannerImage[0].originFileObj.name.lastIndexOf(".")
          );
          formData.append(
            "banner",
            bannerImage[0]?.originFileObj,
            `${currentUser?.user.id}_${Date.now()}_banner_image_url${ext}`
          );
        } else if (bannerImage[0]?.uid === "-1") {
          formData.append("banner", bannerImage[0]?.url);
        }
        // console.log("check the list of formData", formData);
        // dispatch(updateCourse(formData));
      } else {
        // formData.append("banner", "");
        message.error("Please upload banner image");
        return false;
      }
      dispatch(updateNews(formData));
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <Text
        style={{
          paddingBottom: "8px",
          display: "block",
          marginBottom: customStyles.marginSM,
        }}
      >
        <Button
          type="default"
          style={{ color: "#1890ff", marginRight: "8px" }} // Set button color and margin
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate("/news/all")}
        />
        Edit News
      </Text>
      <Card
        style={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        {newsData &&
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              title: newsData?.title,
              description: newsData?.description,
              banner: newsData?.banner,
            }}
          >
            <Row gutter={[16, 16]}>
              {/* left side column */}
              <Col xs={24} md={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      label="Title"
                      name="title"
                      rules={[
                        { required: true, message: "Please enter the title" },
                      ]}
                    >
                      <Input
                        placeholder="Enter title"
                      // value={formData.title}
                      // onChange={(e) => handleInputChange(e, "title")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    {/* <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the description",
                    },
                  ]}
                >
                  
                </Form.Item> */}
                    <Form.Item
                      noStyle
                      label="Description"
                      value={newsData?.description}
                      name="description"
                    ></Form.Item>
                    <Form.Item

                      rules={[
                        {
                          required: true,
                          message: "Please enter content",
                        },
                      ]}
                    >
                      <ReactQuill
                        theme="snow"
                        // value={`<p>dd</p>`}
                        defaultValue={`${newsData?.description}`}
                        onChange={(content, delta, source, editor) => {
                          const editorContent = editor
                            .getHTML();
                          setEditorValue({
                            ...editorValue,
                            description: editorContent,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Banner"
                      name="banner"
                      required
                    // rules={[
                    //   { required: true, message: "Please upload a banner" },
                    // ]}
                    >
                      <UploadImage
                        onUploadImageChange={(file) => {
                          console.log("upload", file);
                          setBannerImage(file);
                        }}
                        // passengerFileList={["", ""]}
                        passengerFileList={bannerImage ? bannerImage : []}
                        formItemKey={"banner"}
                        maxUploadCount={1}
                        cropperRatio={1.5}
                        modalWidth={900}
                      />
                      {/* <Upload
                    beforeUpload={() => false}
                    onChange={handleBannerChange}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={24} style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        }

      </Card>
    </div>
  );
};

export default EditNews;
