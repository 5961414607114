import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Dropdown,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import usecustomStyles from "../../Common/customStyles";
// import { Link } from "react-router-dom";
import { ChevronDown } from "lucide-react";
// import { recentOrders } from "../../Common/data";

import useToast from "../../hooks/useToast";
import { getAllStudents } from "../../slices/thunk";
import { toggleStudentStatus } from "../../slices/student/thunk";

const customStyles = usecustomStyles();
const { Text } = Typography;

function AllStudent() {
  const studentReducer = useSelector((state) => state.Student);
  console.log("Student data", studentReducer.allStudentData);

  const showToast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStudents());
  }, [dispatch]);

  const [loading, setLoading] = useState({});
  const [error, setError] = useState(null);
  console.log("error", error);
  // ------------------------------------------------------------------------
  const [studentStatus, setStudentStatus] = useState({});

  // Assuming studentReducer.allStudentData is the array of instructors
  // Initialize studentStatus state based on allStudentData
  useEffect(() => {
    const initialStatus = {};
    studentReducer?.allStudentData?.forEach((student) => {
      initialStatus[student.id] = student?.is_active;
    });
    setStudentStatus(initialStatus);
  }, [studentReducer.allStudentData]);

  // Modify handleBlock function to update studentStatus state
  // Modify handleBlock function to update loading state

  const handleBlock = (id) => {
    console.log(id);
    // Set loading state to true for the specific switch
    setLoading({ ...loading, [id]: true });

    // Toggle the status
    const updatedStatus = { ...studentStatus };
    updatedStatus[id] = !updatedStatus[id];

    // Dispatch action or call API to update the instructor status
    dispatch(toggleStudentStatus({ user_id: id, is_active: updatedStatus[id] }))
      .unwrap()
      .then(() => {
        // Success message
        showToast("success", "Student status updated successfully");
        setStudentStatus(updatedStatus);
      })
      .catch((error) => {
        // Rollback the status change
        updatedStatus[id] = !updatedStatus[id];
        setStudentStatus(updatedStatus);

        // Display error message
        setError(error.message || "Failed to update student status");
        showToast("error", error.message || "Failed to update student status");
      })
      .finally(() => {
        // Set loading state to false after the operation is complete
        setLoading({ ...loading, [id]: false });
      });
  };

  // ------------------------------------------------------------------------

  const columns = [
    {
      title: "Name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      render: ({ profile_picture, name }) => {
        return (
          <span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img
                  src={profile_picture}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div>
                <h6 style={{ fontSize: "13px" }}>
                  {name?.split("+").join(" ")}
                </h6>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: "Email Adress",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Status",
      dataIndex: "id",
      render: (id) => (
        <Tag color={studentStatus[id] ? "green" : "red"}>
          {studentStatus[id] ? "Active" : "Blocked"}
        </Tag>
      ),
    },
    {
      title: "Block",
      dataIndex: "id",
      render: (id) => (
        <Switch
          checked={studentStatus[id]}
          onClick={() => handleBlock(id)}
          size="small"
          loading={loading[id]}
        />
      ),
    },
  ];

  const pagination = {
    pageSize: 5, // Set the number of items per page
    total: studentReducer?.allStudentData?.length, // Total number of items in your data source
  };

  const items = [
    { label: "Today", key: "0" },
    { label: "Yesterday", key: "1" },
    { label: "Last 7 Days", key: "2" },
    { label: "Last 30 Days", key: "3" },
    { label: "This Month", key: "4" },
    { label: "Last Month", key: "5" },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
        sorter,
        pageSize
      );
    },
  };

  return (
    <>
      <Col xs={24} style={{ marginTop: "125px" }}>
        <Card className="recentCard">
          <div
            style={{
              display: "flex",
              padding: "22px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
            >
              All Students
            </h4>
            <div>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: customStyles.colorText }}
                >
                  <Space>
                    <Text style={{ fontSize: "12px", fontWeight: "500" }}>
                      SORT BY:{" "}
                    </Text>
                    <span
                      style={{
                        fontWeight: "400",
                        color: customStyles.textMuted,
                        fontSize: "12px",
                      }}
                    >
                      Today
                      <ChevronDown size={15} />
                    </span>
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>

          {/* Table */}
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Table
              columns={columns || []}
              dataSource={(studentReducer?.allStudentData || []).map(
                (order, index) => ({
                  ...order,
                  key: index,
                })
              )}
              pagination={pagination}
              rowSelection={{
                ...rowSelection,
              }}
            />
          </div>
        </Card>
      </Col>
    </>
  );
}

export default AllStudent;
