import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

export const getAllNews = createAsyncThunk(
  "auth/getAllNews",
  async ({ page, perPage } = {}) => {
    let url = `${process.env.REACT_APP_BASE_URL}/api/news`;
    if (page !== undefined) {
      url += `?page=${page}`;
      if (perPage !== undefined) {
        url += `&per_page=${perPage}`;
      }
    } else if (perPage !== undefined) {
      url += `?per_page=${perPage}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("response.data in thunk", response.data);
      return response?.data?.news;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const getNewsById = createAsyncThunk(
  "auth/getNewsById",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("response.data in thunk news", response.data);
      return response?.data?.news;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const updateNews = createAsyncThunk(
  "auth/updateNews",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/news/update`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data in thunk updated", response.data.news);
      if (response?.data?.news) {
        message.success("News Updated Successfully");
      }
      return response?.data?.news;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);