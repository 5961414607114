import { createSlice } from "@reduxjs/toolkit";
import {
  changePassword,
  getPersonalInfo,
  login,
  logoutAdmin,
  updatePersonalInfo,
} from "./thunk";
import { adminSignUp } from "../register/thunk";

export const initialState = {
  user: JSON.parse(localStorage.getItem("currentUser")) || null,
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
  personalInfo: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state) {
      state.isUserLogout = true;
    },
    reset_login_flag(state) {
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
    },
    logout(state) {
      state.user = null;
      localStorage.removeItem("currentUser");
      state.isUserLogout = true;
    },
  },
  extraReducers: (builder) => {
    //login
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
      state.isUserLogout = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // Add extra reducers here...
    builder.addCase(adminSignUp.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
      state.isUserLogout = false;
    });

    //getPersonalInfo
    builder.addCase(getPersonalInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPersonalInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.personalInfo = action.payload;
    });
    builder.addCase(getPersonalInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //updatePersonalInfo
    builder.addCase(updatePersonalInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updatePersonalInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.personalInfo = action.payload;

      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      const updatedCurrentUser = { ...currentUser, user: action.payload.user };
      localStorage.setItem("currentUser", JSON.stringify(updatedCurrentUser));
      state.user = updatedCurrentUser;
    });
    builder.addCase(updatePersonalInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //change password
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = false;
      // state.personalInfo = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //log out admin
    builder.addCase(logoutAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logoutAdmin.fulfilled, (state) => {
      state.loading = false;
      state.user = null;
      localStorage.removeItem("currentUser");
      state.isUserLogout = true;
    });
    // builder.addCase(logoutAdmin.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // });
    builder.addCase(logoutAdmin.rejected, (state, action) => {
      state.loading = false;
      console.log("err", action);
      console.log("inside logout thunk ", action?.error?.message);
      if (action?.error?.message.includes("Unauthenticated")) {
        state.user = null;
        localStorage.removeItem("currentUser");
        state.isUserLogout = true;
      }
    });
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  logout,
} = loginSlice.actions;

export default loginSlice.reducer;
