import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllInstructor = createAsyncThunk(
  "auth/getAllInstructor",
  async () => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/instructors`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response in thunk", response);
      return response.data?.users?.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const inviteAInstructor = createAsyncThunk(
  "auth/inviteAInstructor",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/instructor-invitations/store`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response in thunk", response);
      return response.data?.users;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const getAInstructor = createAsyncThunk(
  "auth/getAInstructor",
  async (id) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/admin/instructors/show?user_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("inside get a instructor thunk ", response.data);
      return response.data?.user;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const editInstructor = createAsyncThunk(
  "auth/editInstructor",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/instructors/update`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response in thunk", response);
      return response.data?.users;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

export const toggleInstructorStatus = createAsyncThunk(
  "auth/toggleInstructorStatus",
  async (body) => {
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/instructors/toggle-active-status`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response in thunk", response);
      return response.data?.users;
    } catch (error) {
      console.log("error in thunk", error);
      // throw error.response.data.message;
      throw error.response.data.message;
    }
  }
);
