// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Card, Col, Dropdown, Space, Table, Typography, Button } from "antd";
// import usecustomStyles from "../../Common/customStyles";
// import { ChevronDown } from "lucide-react";

// import { getAllLesson } from "../../slices/thunk";

// const customStyles = usecustomStyles();
// const { Text } = Typography;

// function AllLesson() {
//   const aiBotReducer = useSelector((state) => state.AIBot);
//   console.log("lesson data", aiBotReducer?.allLessonData?.data);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllLesson());
//   }, [dispatch]);

//   const handleEdit = (id) => {
//     console.log("id", id);
//   };
//   const handleView = (id) => {
//     console.log("id", id);
//   };

//   const columns = [
//     {
//       title: "User Information",
//       dataIndex: "user",
//       render: (user) => (
//         <span style={{ display: "block" }}>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               // justifyContent: "center",
//             }}
//           >
//             <div>
//               <img
//                 src={user.profile_picture}
//                 alt=""
//                 style={{
//                   borderRadius: "50%",
//                   height: "32px",
//                   width: "32px",
//                   marginRight: "10px",
//                 }}
//               />
//             </div>
//             <div>
//               <h6 style={{ fontSize: "13px" }}>
//                 {user.name?.split("+").join(" ")}
//               </h6>
//               <div
//                 style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
//               >
//                 {user.email}
//               </div>
//             </div>
//           </div>
//         </span>
//       ),
//     },
//     {
//       title: "Duration Time",
//       dataIndex: "start_datetime",
//       render: (start_datetime, record) => {
//         const startTime = new Date(start_datetime);
//         const endTime = new Date(record.end_datetime);
//         const duration = (endTime - startTime) / 1000; // duration in seconds
//         return <span>{duration} seconds</span>;
//       },
//       sorter: (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime),
//     },

//     {
//       title: "Type Name",
//       dataIndex: "types",
//       render: (types) => types.type_name,
//     },
//     {
//       title: <div style={{ textAlign: "center" }}>Action</div>,
//       dataIndex: "id",
//       render: (id) => (
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             gap: "10px",
//             // backgroundColor: "red",
//           }}
//         >
//           <Button type="primary" size="small" onClick={() => handleView(id)}>
//             View
//           </Button>
//           <Button type="primary" size="small" onClick={() => handleEdit(id)}>
//             Edit
//           </Button>
//         </div>
//       ),
//     },
//   ];

//   const pagination = {
//     pageSize: 10,
//     total: aiBotReducer?.allLessonData?.data?.length,
//   };

//   const items = [
//     { label: "Today", key: "0" },
//     { label: "Yesterday", key: "1" },
//     { label: "Last 7 Days", key: "2" },
//     { label: "Last 30 Days", key: "3" },
//     { label: "This Month", key: "4" },
//     { label: "Last Month", key: "5" },
//   ];

//   const rowSelection = {
//     onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
//       console.log(
//         `selectedRowKeys: ${selectedRowKeys}`,
//         "selectedRows: ",
//         selectedRows,
//         sorter,
//         pageSize
//       );
//     },
//   };

//   return (
//     <>
//       <Col xs={24} style={{ marginTop: "125px" }}>
//         <Card className="recentCard">
//           <div
//             style={{
//               display: "flex",
//               padding: "22px",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <h4
//               style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
//             >
//               All Reservation
//             </h4>
//             <div>
//               <Dropdown
//                 menu={{
//                   items,
//                 }}
//                 trigger={["click"]}
//               >
//                 <a
//                   href="/#"
//                   onClick={(e) => e.preventDefault()}
//                   style={{ color: customStyles.colorText }}
//                 >
//                   <Space>
//                     <Text style={{ fontSize: "12px", fontWeight: "500" }}>
//                       SORT BY:{" "}
//                     </Text>
//                     <span
//                       style={{
//                         fontWeight: "400",
//                         color: customStyles.textMuted,
//                         fontSize: "12px",
//                       }}
//                     >
//                       Today
//                       <ChevronDown size={15} />
//                     </span>
//                   </Space>
//                 </a>
//               </Dropdown>
//             </div>
//           </div>

//           {/* Table */}
//           <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
//             <Table
//               columns={columns || []}
//               dataSource={(aiBotReducer?.allLessonData?.data || []).map(
//                 (reservation, index) => ({
//                   ...reservation,
//                   key: index,
//                 })
//               )}
//               pagination={pagination}
//               rowSelection={{
//                 ...rowSelection,
//               }}
//             />
//           </div>
//         </Card>
//       </Col>
//     </>
//   );
// }

// export default AllLesson;

// -----------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------

// import { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Card,
//   Col,
//   Dropdown,
//   Space,
//   Table,
//   Typography,
//   Button,
//   Modal,
// } from "antd";
// import usecustomStyles from "../../Common/customStyles";
// import { ChevronDown } from "lucide-react";

// import { getAllLesson } from "../../slices/thunk";

// const customStyles = usecustomStyles();
// const { Text } = Typography;

// function AllLesson() {
//   const aiBotReducer = useSelector((state) => state.AIBot);
//   console.log("lesson data", aiBotReducer?.allLessonData?.data);

//   const dispatch = useDispatch();

//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [modalContent, setModalContent] = useState(null);

//   useEffect(() => {
//     dispatch(getAllLesson());
//   }, [dispatch]);

//   const handleEdit = (id) => {
//     console.log("id", id);
//   };

//   const handleView = (id) => {
//     const lesson = aiBotReducer?.allLessonData?.data?.find(
//       (lesson) => lesson.id === id
//     );

//     // setModalContent(JSON.parse(lesson?.content));
//     setModalContent(lesson);
//     setIsModalVisible(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalVisible(false);
//     setModalContent(null);
//   };

//   const columns = [
//     {
//       title: "User Information",
//       dataIndex: "user",
//       render: (user) => (
//         <span style={{ display: "block" }}>
//           <div style={{ display: "flex", alignItems: "center" }}>
//             <div>
//               <img
//                 src={user.profile_picture}
//                 alt=""
//                 style={{
//                   borderRadius: "50%",
//                   height: "32px",
//                   width: "32px",
//                   marginRight: "10px",
//                 }}
//               />
//             </div>
//             <div>
//               <h6 style={{ fontSize: "13px" }}>
//                 {user.name?.split("+").join(" ")}
//               </h6>
//               <div
//                 style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
//               >
//                 {user.email}
//               </div>
//             </div>
//           </div>
//         </span>
//       ),
//     },
//     {
//       title: "Duration Time",
//       dataIndex: "start_datetime",
//       render: (start_datetime, record) => {
//         const startTime = new Date(start_datetime);
//         const endTime = new Date(record.end_datetime);
//         const duration = (endTime - startTime) / 1000; // duration in seconds
//         return <span>{duration} seconds</span>;
//       },
//       sorter: (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime),
//     },
//     {
//       title: "Type Name",
//       dataIndex: "types",
//       render: (types) => types.type_name,
//     },
//     {
//       title: <div style={{ textAlign: "center" }}>Action</div>,
//       dataIndex: "id",
//       render: (id) => (
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             gap: "10px",
//           }}
//         >
//           <Button type="primary" size="small" onClick={() => handleView(id)}>
//             View
//           </Button>
//           <Button type="primary" size="small" onClick={() => handleEdit(id)}>
//             Edit
//           </Button>
//         </div>
//       ),
//     },
//   ];

//   const pagination = {
//     pageSize: 10,
//     total: aiBotReducer?.allLessonData?.data?.length,
//   };

//   const items = [
//     { label: "Today", key: "0" },
//     { label: "Yesterday", key: "1" },
//     { label: "Last 7 Days", key: "2" },
//     { label: "Last 30 Days", key: "3" },
//     { label: "This Month", key: "4" },
//     { label: "Last Month", key: "5" },
//   ];

//   const rowSelection = {
//     onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
//       console.log(
//         `selectedRowKeys: ${selectedRowKeys}`,
//         "selectedRows: ",
//         selectedRows,
//         sorter,
//         pageSize
//       );
//     },
//   };

//   const renderConversation = (lesson) => {
//     const conversation = JSON.parse(lesson?.content);
//     return conversation.map((entry, index) => (
//       <div
//         key={index}
//         style={{
//           display: "flex",
//           justifyContent: entry.fromAi ? "flex-start" : "flex-end",
//           marginBottom: "10px",
//         }}
//       >
//         <div
//           style={{
//             maxWidth: "60%",
//             padding: "10px",
//             borderRadius: "10px",
//             backgroundColor: entry.fromAi ? "#f0f0f0" : "#1890ff",
//             color: entry.fromAi ? "#000" : "#fff",
//           }}
//         >
//           {entry.transcript}
//         </div>
//       </div>
//     ));
//   };

//   return (
//     <>
//       <Col xs={24} style={{ marginTop: "125px" }}>
//         <Card className="recentCard">
//           <div
//             style={{
//               display: "flex",
//               padding: "22px",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <h4
//               style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
//             >
//               All Reservation
//             </h4>
//             <div>
//               <Dropdown menu={{ items }} trigger={["click"]}>
//                 <a
//                   href="/#"
//                   onClick={(e) => e.preventDefault()}
//                   style={{ color: customStyles.colorText }}
//                 >
//                   <Space>
//                     <Text style={{ fontSize: "12px", fontWeight: "500" }}>
//                       SORT BY:{" "}
//                     </Text>
//                     <span
//                       style={{
//                         fontWeight: "400",
//                         color: customStyles.textMuted,
//                         fontSize: "12px",
//                       }}
//                     >
//                       Today
//                       <ChevronDown size={15} />
//                     </span>
//                   </Space>
//                 </a>
//               </Dropdown>
//             </div>
//           </div>

//           {/* Table */}
//           <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
//             <Table
//               columns={columns || []}
//               dataSource={(aiBotReducer?.allLessonData?.data || []).map(
//                 (reservation, index) => ({
//                   ...reservation,
//                   key: index,
//                 })
//               )}
//               pagination={pagination}
//               rowSelection={{ ...rowSelection }}
//             />
//           </div>
//         </Card>
//       </Col>

//       {/* Modal */}
//       <Modal
//         title="Lesson Details"
//         visible={isModalVisible}
//         onOk={handleCloseModal}
//         onCancel={handleCloseModal}
//         footer={[
//           <Button key="back" onClick={handleCloseModal}>
//             Close
//           </Button>,
//         ]}
//       >
//         {modalContent && renderConversation(modalContent)}
//       </Modal>
//     </>
//   );
// }

// export default AllLesson;

// ----------------------------------------------------------------
// ----------------------------------------------------------------

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Dropdown,
  Space,
  Table,
  Typography,
  Button,
  Modal,
} from "antd";
import usecustomStyles from "../../Common/customStyles";
import { ChevronDown } from "lucide-react";

import { getAllLesson } from "../../slices/thunk";
import { formatDateString } from "../../Util/helperFunctions";

const customStyles = usecustomStyles();
const { Text } = Typography;

function AllLesson() {
  const aiBotReducer = useSelector((state) => state.AIBot);
  console.log("lesson data", aiBotReducer?.allLessonData?.data);

  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    dispatch(getAllLesson());
  }, [dispatch]);

  const handleEdit = (id) => {
    console.log("id", id);
  };

  const handleDownload = (id) => {
    console.log("id", id);
  };

  const handleView = (id) => {
    const lesson = aiBotReducer?.allLessonData?.data?.find(
      (lesson) => lesson.id === id
    );

    setModalContent(lesson);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  const columns = [
    {
      title: "User Information",
      dataIndex: "user",
      render: (user) => (
        <span style={{ display: "block" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img
                src={user.profile_picture}
                alt=""
                style={{
                  borderRadius: "50%",
                  height: "32px",
                  width: "32px",
                  marginRight: "10px",
                }}
              />
            </div>
            <div>
              <h6 style={{ fontSize: "13px" }}>
                {user.name?.split("+").join(" ")}
              </h6>
              <div
                style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
              >
                {user.email}
              </div>
            </div>
          </div>
        </span>
      ),
    },
    // {
    //   title: "Duration Time",
    //   dataIndex: "start_datetime",
    //   render: (start_datetime, record) => {
    //     const startTime = new Date(start_datetime);
    //     const endTime = new Date(record.end_datetime);
    //     const duration = (endTime - startTime) / 1000; // duration in seconds
    //     return <span>{duration} seconds</span>;
    //   },
    //   sorter: (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime),
    // },
    {
      title: "Duration Time",
      dataIndex: "start_datetime",
      render: (start_datetime, record) => {
        const startTime = new Date(start_datetime);
        const endTime = new Date(record.end_datetime);
        const durationInSeconds = (endTime - startTime) / 1000;
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        return (
          <span>
            {minutes} min {seconds} sec
          </span>
        );
      },
      sorter: (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime),
    },

    {
      title: "Type Name",
      dataIndex: "types",
      render: (types) => types.type_name,
    },
    {
      title: <div style={{ textAlign: "center" }}>Action</div>,
      dataIndex: "id",
      render: (id) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Button type="primary" size="small" onClick={() => handleView(id)}>
            View
          </Button>
          <Button type="primary" size="small" onClick={() => handleEdit(id)}>
            Edit
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => handleDownload(id)}
          >
            Download
          </Button>
        </div>
      ),
    },
  ];

  const pagination = {
    pageSize: 10,
    total: aiBotReducer?.allLessonData?.data?.length,
  };

  const items = [
    { label: "Today", key: "0" },
    { label: "Yesterday", key: "1" },
    { label: "Last 7 Days", key: "2" },
    { label: "Last 30 Days", key: "3" },
    { label: "This Month", key: "4" },
    { label: "Last Month", key: "5" },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
        sorter,
        pageSize
      );
    },
  };

  const renderConversation = (lesson) => {
    const conversation = JSON.parse(lesson?.content);
    const userPicture = lesson?.user?.profile_picture;
    const dummyProfilePicture = "/ai.jpg";

    return conversation.map((entry, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          justifyContent: entry.fromAi ? "flex-start" : "flex-end",
          marginBottom: "20px",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        {entry.fromAi && (
          <img
            src={dummyProfilePicture}
            alt="AI"
            style={{
              borderRadius: "50%",
              height: "32px",
              width: "32px",
              marginRight: "10px",
            }}
          />
        )}
        <div
          style={{
            maxWidth: "60%",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: entry.fromAi ? "#f0f0f0" : "#1890ff",
            color: entry.fromAi ? "#000" : "#fff",
            margin: entry.fromAi ? "0 10px 0 0" : "0 0 0 10px",
          }}
        >
          {entry.transcript}
        </div>
        {!entry.fromAi && (
          <img
            src={userPicture}
            alt="User"
            style={{
              borderRadius: "50%",
              height: "32px",
              width: "32px",
              marginLeft: "10px",
            }}
          />
        )}
      </div>
    ));
  };

  return (
    <>
      <Col xs={24} style={{ marginTop: "125px" }}>
        <Card className="recentCard">
          <div
            style={{
              display: "flex",
              padding: "22px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
            >
              All Reservation
            </h4>
            <div>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <a
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: customStyles.colorText }}
                >
                  <Space>
                    <Text style={{ fontSize: "12px", fontWeight: "500" }}>
                      SORT BY:{" "}
                    </Text>
                    <span
                      style={{
                        fontWeight: "400",
                        color: customStyles.textMuted,
                        fontSize: "12px",
                      }}
                    >
                      Today
                      <ChevronDown size={15} />
                    </span>
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>

          {/* Table */}
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Table
              columns={columns || []}
              dataSource={(aiBotReducer?.allLessonData?.data || []).map(
                (reservation, index) => ({
                  ...reservation,
                  key: index,
                })
              )}
              pagination={pagination}
              rowSelection={{ ...rowSelection }}
            />
          </div>
        </Card>
      </Col>

      {/* Modal */}
      <Modal
        title={
          modalContent
            ? `Lesson Details (${formatDateString(
                modalContent?.start_datetime
              )} - ${formatDateString(modalContent?.end_datetime)})`
            : "Lesson Details"
        }
        visible={isModalVisible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      >
        {modalContent && renderConversation(modalContent)}
      </Modal>
    </>
  );
}

export default AllLesson;
