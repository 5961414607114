import { combineReducers } from "@reduxjs/toolkit";

import APIKeyslice from "./apiKey/reducer";

import TodoReducer from "./todo/reducer";

import Contactslice from "./contact/reducer";

import EmailReducer from "./email/reducer";

import chatReducer from "./chat/reducer";

import TasksReducer from "./kanban/reducer";

import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import instructorReducer from "./instructor/reducer";
import courseReducer from "./course/reducer";
import newsReducer from "./news/reducer";
import studentReducer from "./student/reucer";
import reservationReducer from "./reservation/reducer";
import invitationReducer from "./invitations/reducer";
import AIBotReducer from "./Ai-bot/reducer";
import notificationsReducer from "./Notification/reducer";

const rootReducer = combineReducers({
  APIKey: APIKeyslice,
  Contacts: Contactslice,
  Todo: TodoReducer,
  Email: EmailReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  ForgetPassword: ForgetPasswordReducer,
  Chat: chatReducer,
  Tasks: TasksReducer,
  // add later
  Instructor: instructorReducer,
  Course: courseReducer,
  News: newsReducer,
  Student: studentReducer,
  Invitations: invitationReducer,
  Reservations: reservationReducer,
  AIBot: AIBotReducer,
  Notifications: notificationsReducer,
});

export default rootReducer;
