import { message } from "antd";
import { useCallback } from "react";

const useToast = () => {
  const showToast = useCallback((type, content) => {
    switch (type) {
      case "success":
        message.success(content);
        break;
      case "error":
        message.error(content);
        break;
      default:
        message.info(content);
        break;
    }
  }, []);

  return showToast;
};

export default useToast;
