import { useEffect, useState } from "react";
import { useThunk } from "../../hooks/useThunk";
import {
  editInstructor,
  getAInstructor,
  getAllCourses,
} from "../../slices/thunk";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  Input,
  Switch,
  Typography,
  Row,
  Col,
  Select,
} from "antd";
import usecustomStyles from "../../Common/customStyles";
import TextArea from "antd/es/input/TextArea";
import { ArrowLeftOutlined } from "@ant-design/icons";

const customStyles = usecustomStyles();
const { Text } = Typography;
const { Option } = Select;

function EditInstructorAction() {
  const [fetchAInstructor] = useThunk(getAInstructor);
  const [fetchAllCourse] = useThunk(getAllCourses);
  const [updateInstructor, loading] = useThunk(
    editInstructor,
    "Instructor update Successfully"
  );

  // get id from the url
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedInstructor } = useSelector((state) => {
    return state.Instructor;
  });
  const { allCourseData } = useSelector((state) => {
    return state.Course;
  });

  console.log("selectedInstructor data", selectedInstructor);
  console.log("allCourse data", allCourseData?.data);

  useEffect(() => {
    fetchAInstructor(id);
  }, [id, fetchAInstructor]);

  useEffect(() => {
    fetchAllCourse();
  }, [fetchAllCourse]);

  useEffect(() => {
    if (!selectedInstructor) {
      return;
    }
    const formdata = {
      name: selectedInstructor?.name?.split("+").join(" "),
      phone: selectedInstructor?.phone ? selectedInstructor.phone : "",
      email: selectedInstructor?.email ? selectedInstructor.email : "",
      nickname: selectedInstructor?.instructor?.nickname
        ? selectedInstructor?.instructor?.nickname
        : "",
      facebook_url: selectedInstructor?.instructor?.facebook_url
        ? selectedInstructor?.instructor?.facebook_url
        : "",
      line_url: selectedInstructor?.instructor?.line_url
        ? selectedInstructor?.instructor?.line_url
        : "",
      linkedin_url: selectedInstructor?.instructor?.linkedin_url
        ? selectedInstructor?.instructor?.linkedin_url
        : "",
      instagram_url: selectedInstructor?.instructor?.instagram_url
        ? selectedInstructor?.instructor?.instagram_url
        : "",
      additionalInfo: selectedInstructor?.instructor?.short_details
        ? selectedInstructor?.instructor?.short_details
        : "",
      nameIndicator: selectedInstructor?.instructor?.field_indicator?.name
        ? selectedInstructor?.instructor?.field_indicator?.name
        : false,
      phoneIndicator: selectedInstructor?.instructor?.field_indicator?.phone
        ? selectedInstructor?.instructor?.field_indicator?.phone
        : false,
      emailIndicator: selectedInstructor?.instructor?.field_indicator?.email
        ? selectedInstructor?.instructor?.field_indicator?.email
        : false,
      nicknameIndicator: selectedInstructor?.instructor?.field_indicator
        ?.nickname
        ? selectedInstructor?.instructor?.field_indicator?.nickname
        : false,
      facebookUrlIndicator: selectedInstructor?.instructor?.field_indicator
        ?.facebook_url
        ? selectedInstructor?.instructor?.field_indicator?.facebook_url
        : false,
      lineUrlIndicator: selectedInstructor?.instructor?.field_indicator
        ?.line_url
        ? selectedInstructor?.instructor?.field_indicator?.line_url
        : false,
      linkedinUrlIndicator: selectedInstructor?.instructor?.field_indicator
        ?.linkedin_url
        ? selectedInstructor?.instructor?.field_indicator?.linkedin_url
        : false,
      instagramUrlIndicator: selectedInstructor?.instructor?.field_indicator
        ?.instagram_url
        ? selectedInstructor?.instructor?.field_indicator?.instagram_url
        : false,
      additionalInfoIndicator: selectedInstructor?.instructor?.field_indicator
        ?.short_details
        ? selectedInstructor?.instructor?.field_indicator?.short_details
        : false,
      courses:
        selectedInstructor?.instructor?.courses.map((course) => course.id) ||
        [],
    };
    setFormData(formdata);
  }, [selectedInstructor]);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    nickname: "",
    facebook_url: "",
    line_url: "",
    linkedin_url: "",
    instagram_url: "",
    nameIndicator: false,
    phoneIndicator: false,
    emailIndicator: false,
    nicknameIndicator: false,
    facebookUrlIndicator: false,
    lineUrlIndicator: false,
    linkedinUrlIndicator: false,
    instagramUrlIndicator: false,
    additionalInfo: "", // New field for additional information
    additionalInfoIndicator: false, // Indicator for additional information
    courses: [],
  });
  const handleCoursesChange = (value) => {
    console.log("value", value);
    setFormData({ ...formData, courses: value });
  };

  const handleInputChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const handleSwitchChange = (checked, key) => {
    setFormData({ ...formData, [key]: checked });
  };

  const handleSubmit = () => {
    const submitData = {
      name: formData.nameIndicator ? formData.name : false,
      phone: formData.phoneIndicator ? formData.phone : false,
      email: formData.emailIndicator ? formData.email : false,
      nickname: formData.nicknameIndicator ? formData.nickname : false,
      facebook_url: formData.facebookUrlIndicator
        ? formData.facebook_url
        : false,
      line_url: formData.lineUrlIndicator ? formData.line_url : false,
      linkedin_url: formData.linkedinUrlIndicator
        ? formData.linkedin_url
        : false,
      instagram_url: formData.instagramUrlIndicator
        ? formData.instagram_url
        : false,
      additionalInfo: formData.additionalInfoIndicator
        ? formData.additionalInfo
        : false,
      courses: formData.courses,
    };

    const body = {
      user_id: id,
      short_details: formData.additionalInfo,
      field_indicator: JSON.stringify({
        about_me: true,
        email: formData.emailIndicator,
        facebook_url: formData.facebookUrlIndicator,
        instagram_url: formData.instagramUrlIndicator,
        line_url: formData.lineUrlIndicator,
        linkedin_url: formData.linkedinUrlIndicator,
        name: formData.nameIndicator,
        nickname: formData.nicknameIndicator,
        phone: formData.phoneIndicator,
        short_details: formData.additionalInfoIndicator,
      }),
      // courses:[1,2,3,4,5,6,7,8]
      courses: formData.courses,
    };

    console.log(submitData);
    console.log(body);

    updateInstructor(body);
  };

  console.log("formData.courses", formData.courses);

  return (
    <>
      <div style={{ marginTop: "70px" }}>
        <Text
          style={{
            paddingBottom: "8px",
            display: "block",
            marginBottom: customStyles.marginSM,
          }}
        >
          <Button
            type="default"
            style={{ color: "#1890ff", marginRight: "8px" }} // Set button color and margin
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate("/instructor/all")}
          />
          Edit Instructor
        </Text>
      </div>
      <Card>
        <Form
          onFinish={handleSubmit}
          style={{
            padding: "20px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Form.Item label="Name">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.name}
                      onChange={(e) => handleInputChange(e, "name")}
                      //   readOnly={!formData.nameIndicator}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "nameIndicator")
                      }
                      checked={formData.nameIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Phone">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.phone}
                      onChange={(e) => handleInputChange(e, "phone")}
                      //   readOnly={!formData.phoneIndicator}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "phoneIndicator")
                      }
                      checked={formData.phoneIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Email">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.email}
                      onChange={(e) => handleInputChange(e, "email")}
                      //   readOnly={!formData.emailIndicator}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "emailIndicator")
                      }
                      checked={formData.emailIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="LinkedIn URL">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.linkedin_url}
                      onChange={(e) => handleInputChange(e, "linkedin_url")}
                      //   readOnly={!formData.linkedinUrlIndicator}
                      readOnly
                      style={{ textAlign: "left" }} // Aligning left
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "linkedinUrlIndicator")
                      }
                      checked={formData.linkedinUrlIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Additional Information">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <TextArea
                      style={{ height: 120, resize: "none" }}
                      value={formData.additionalInfo}
                      onChange={(e) => handleInputChange(e, "additionalInfo")}
                      readOnly={!formData.additionalInfoIndicator}
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "additionalInfoIndicator")
                      }
                      checked={formData.additionalInfoIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label="Nickname">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.nickname}
                      onChange={(e) => handleInputChange(e, "nickname")}
                      //   readOnly={!formData.nicknameIndicator}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "nicknameIndicator")
                      }
                      checked={formData.nicknameIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Facebook URL">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.facebook_url}
                      onChange={(e) => handleInputChange(e, "facebook_url")}
                      //   readOnly={!formData.facebookUrlIndicator}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "facebookUrlIndicator")
                      }
                      checked={formData.facebookUrlIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Line URL">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.line_url}
                      onChange={(e) => handleInputChange(e, "line_url")}
                      //   readOnly={!formData.lineUrlIndicator}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "lineUrlIndicator")
                      }
                      checked={formData.lineUrlIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Instagram URL">
                <Row gutter={[8, 8]} align="middle">
                  <Col flex="auto">
                    <Input
                      value={formData.instagram_url}
                      onChange={(e) => handleInputChange(e, "instagram_url")}
                      //   readOnly={!formData.lineUrlIndicator}
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Switch
                      size="small"
                      onChange={(checked) =>
                        handleSwitchChange(checked, "instagramUrlIndicator")
                      }
                      checked={formData.instagramUrlIndicator}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Courses">
                <Select
                  mode="multiple"
                  value={formData.courses}
                  onChange={handleCoursesChange}
                  style={{ width: "100%" }}
                  placeholder="Select courses"
                >
                  {allCourseData?.data &&
                    allCourseData?.data?.map((course) => (
                      <Option key={course.id} value={course.id}>
                        {course.title}
                      </Option>
                    ))}
                  {/* Add more options as needed */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div style={{ marginTop: "20px" }} className="text-center">
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: customStyles.colorDanger }}
              loading={loading}
            >
              Edit Instructor
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
}

export default EditInstructorAction;
