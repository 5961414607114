import { createSlice } from "@reduxjs/toolkit";
import {
  editInstructor,
  getAInstructor,
  getAllInstructor,
  inviteAInstructor,
} from "./thunk";

export const initialState = {
  allInstructorData: [],
  error: null,
  loading: false,
  selectedInstructor: null,
};

const instructor = createSlice({
  name: "instructor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all instructor
    builder.addCase(getAllInstructor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllInstructor.fulfilled, (state, action) => {
      state.loading = false;
      state.allInstructorData = action.payload;
    });
    builder.addCase(getAllInstructor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //instructor invitations
    builder.addCase(inviteAInstructor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(inviteAInstructor.fulfilled, (state) => {
      state.loading = false;
      // state.allInstructorData = action.payload;
    });
    builder.addCase(inviteAInstructor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //get a instructor
    builder.addCase(getAInstructor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAInstructor.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedInstructor = action.payload;
    });
    builder.addCase(getAInstructor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //Update a instructor
    builder.addCase(editInstructor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editInstructor.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedInstructor = action.payload;
    });
    builder.addCase(editInstructor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default instructor.reducer;
