// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Card,
//   Col,
//   Dropdown,
//   Space,
//   Table,
//   Tag,
//   Typography,
//   Button,
// } from "antd";
// import usecustomStyles from "../../Common/customStyles";
// import { ChevronDown } from "lucide-react";

// import { getAllReservation } from "../../slices/thunk";
// import { monthDayAndYear, onlyTime } from "../../Util/helperFunctions";
// import dayjs from "dayjs";

// const customStyles = usecustomStyles();
// const { Text } = Typography;

// function AllReservation() {
//   const reservationReducer = useSelector((state) => state.Reservations);
//   console.log("reservation data", reservationReducer.allReservationData);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllReservation());
//   }, [dispatch]);

//   const statusCode = {
//     1: { text: "pending", color: "orange" },
//     2: { text: "accepted", color: "blue" },
//     3: { text: "completed", color: "green" },
//     "-1": { text: "rejected", color: "red" },
//   };

//   const handleEdit = (id) => {
//     console.log("id", id);
//   };

//   const isToday = (date) => {
//     return dayjs(date).isSame(dayjs(), "day");
//   };

//   const columns = [
//     // {
//     //   title: "Date Time",
//     //   dataIndex: "start_datetime",
//     //   render: (start_datetime, record) => (
//     //     // <span>
//     //     //   {new Date(start_datetime).toLocaleString()} -{" "}
//     //     //   {new Date(record.end_datetime).toLocaleString()}
//     //     // </span>
//     //     <div>
//     //       <div>
//     //         {monthDayAndYear(start_datetime)}
//     //         {isToday(start_datetime) && (
//     //           <Tag color="#f50" style={{ marginLeft: 4 }}>
//     //             Today
//     //           </Tag>
//     //         )}
//     //       </div>
//     //       <div>Start: {onlyTime(start_datetime)}</div>
//     //       <div>End: {onlyTime(record.end_datetime)}</div>
//     //     </div>
//     //   ),
//     //   sorter: (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime),
//     // },
//     {
//       title: "Date Time",
//       dataIndex: "start_datetime",
//       render: (start_datetime, record) => {
//         const containerStyle = {
//           display: "flex",
//           flexDirection: "column",
//           fontSize: "14px",
//           lineHeight: "1.5",
//           padding: "8px",
//         };

//         const dateStyle = {
//           fontWeight: "bold",
//           marginBottom: "4px",
//         };

//         const timeStyle = {
//           // marginBottom: "2px",
//           fontSize: 11,
//         };

//         return (
//           <div style={containerStyle}>
//             <div style={dateStyle}>
//               {monthDayAndYear(start_datetime)}
//               {isToday(start_datetime) && (
//                 <Tag color="#f50" style={{ marginLeft: 10 }}>
//                   Today
//                 </Tag>
//               )}
//             </div>
//             <div style={timeStyle}>Start: {onlyTime(start_datetime)}</div>
//             <div style={timeStyle}>End: {onlyTime(record.end_datetime)}</div>
//           </div>
//         );
//       },
//       sorter: (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime),
//     },
//     {
//       title: "Student Name",
//       dataIndex: "studentuser",
//       // render: (studentuser) => studentuser.name.split("+").join(" "),
//       render: (studentuser) => {
//         return (
//           <span style={{ display: "block" }}>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 // justifyContent: "center",
//               }}
//             >
//               <div>
//                 <img
//                   src={studentuser.profile_picture}
//                   alt=""
//                   style={{
//                     borderRadius: "50%",
//                     height: "32px",
//                     width: "32px",
//                     marginRight: "10px",
//                   }}
//                 />
//               </div>
//               <div>
//                 <h6 style={{ fontSize: "13px" }}>
//                   {studentuser.name?.split("+").join(" ")}
//                 </h6>
//                 <div
//                   style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
//                 >
//                   {studentuser.email}
//                 </div>
//               </div>
//             </div>
//           </span>
//         );
//       },
//     },
//     {
//       title: "Instructor Name",
//       dataIndex: "instructor",
//       // render: (instructor) => instructor.name.split("+").join(" "),
//       render: (instructor) => {
//         return (
//           <span style={{ display: "block" }}>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 // justifyContent: "center",
//               }}
//             >
//               <div>
//                 <img
//                   src={instructor.profile_picture}
//                   alt=""
//                   style={{
//                     borderRadius: "50%",
//                     height: "32px",
//                     width: "32px",
//                     marginRight: "10px",
//                   }}
//                 />
//               </div>
//               <div>
//                 <h6 style={{ fontSize: "13px" }}>
//                   {instructor.name?.split("+").join(" ")}
//                 </h6>
//                 <div
//                   style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
//                 >
//                   {instructor.email}
//                 </div>
//               </div>
//             </div>
//           </span>
//         );
//       },
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       render: (status) => (
//         <Tag color={statusCode[status].color}>{statusCode[status].text}</Tag>
//       ),
//     },
//     // {
//     //   title: "Remarks",
//     //   dataIndex: "remarks",
//     //   render: (remarks) => (
//     //     <div style={{ display: "block", width: "200px" }}>
//     //       <div style={{ display: "flex", flexWrap: "wrap" }}>
//     //         {remarks || "No remarks"}
//     //       </div>
//     //     </div>
//     //   ),
//     // },
//     {
//       title: "Remarks",
//       dataIndex: "remarks",
//       render: (remarks) => (
//         <div
//           style={{
//             width: "200px",
//             whiteSpace: "normal",
//             wordWrap: "break-word",
//           }}
//         >
//           {remarks || "No remarks"}
//         </div>
//       ),
//     },
//     {
//       title: "Action",
//       dataIndex: "id",
//       render: (id) => (
//         <Button type="primary" size="small" onClick={() => handleEdit(id)}>
//           Edit
//         </Button>
//       ),
//     },
//   ];

//   const pagination = {
//     pageSize: 5,
//     total: reservationReducer?.allReservationData?.length,
//   };

//   const items = [
//     { label: "Today", key: "0" },
//     { label: "Yesterday", key: "1" },
//     { label: "Last 7 Days", key: "2" },
//     { label: "Last 30 Days", key: "3" },
//     { label: "This Month", key: "4" },
//     { label: "Last Month", key: "5" },
//   ];

//   const rowSelection = {
//     onChange: (selectedRowKeys, selectedRows, sorter, pageSize) => {
//       console.log(
//         `selectedRowKeys: ${selectedRowKeys}`,
//         "selectedRows: ",
//         selectedRows,
//         sorter,
//         pageSize
//       );
//     },
//   };

//   return (
//     <>
//       <Col xs={24} style={{ marginTop: "125px" }}>
//         <Card className="recentCard">
//           <div
//             style={{
//               display: "flex",
//               padding: "22px",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <h4
//               style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
//             >
//               All Reservation
//             </h4>
//             <div>
//               <Dropdown
//                 menu={{
//                   items,
//                 }}
//                 trigger={["click"]}
//               >
//                 <a
//                   href="/#"
//                   onClick={(e) => e.preventDefault()}
//                   style={{ color: customStyles.colorText }}
//                 >
//                   <Space>
//                     <Text style={{ fontSize: "12px", fontWeight: "500" }}>
//                       SORT BY:{" "}
//                     </Text>
//                     <span
//                       style={{
//                         fontWeight: "400",
//                         color: customStyles.textMuted,
//                         fontSize: "12px",
//                       }}
//                     >
//                       Today
//                       <ChevronDown size={15} />
//                     </span>
//                   </Space>
//                 </a>
//               </Dropdown>
//             </div>
//           </div>

//           {/* Table */}
//           <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
//             <Table
//               columns={columns || []}
//               dataSource={(reservationReducer?.allReservationData || []).map(
//                 (reservation, index) => ({
//                   ...reservation,
//                   key: index,
//                 })
//               )}
//               pagination={pagination}
//               rowSelection={{
//                 ...rowSelection,
//               }}
//             />
//           </div>
//         </Card>
//       </Col>
//     </>
//   );
// }

// export default AllReservation;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  // Dropdown,
  // Space,
  Table,
  Tag,
  // Typography,
  Button,
  Select,
} from "antd";
// import usecustomStyles from "../../Common/customStyles";
// import { ChevronDown } from "lucide-react";

import { getAllReservation } from "../../slices/thunk";
import { monthDayAndYear, onlyTime } from "../../Util/helperFunctions";
import dayjs from "dayjs";

// const customStyles = usecustomStyles();
// const { Text } = Typography;
const { Option } = Select;

function AllReservation() {
  const reservationReducer = useSelector((state) => state.Reservations);
  const [filteredData, setFilteredData] = useState([]);
  const [filterOption, setFilterOption] = useState("today");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReservation());
  }, [dispatch]);

  useEffect(() => {
    if (reservationReducer.allReservationData) {
      applyFilter(filterOption);
    }
  }, [reservationReducer.allReservationData, filterOption]);

  const applyFilter = (option) => {
    let data = [...reservationReducer.allReservationData];
    switch (option) {
      case "today": {
        const todayReservations = data.filter((reservation) =>
          isToday(reservation.start_datetime)
        );
        const otherReservations = data.filter(
          (reservation) => !isToday(reservation.start_datetime)
        );
        setFilteredData([...todayReservations, ...otherReservations]);
        break;
      }
      case "oldest": {
        data.sort(
          (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime)
        );
        setFilteredData(data);
        break;
      }
      case "latest": {
        data.sort(
          (a, b) => new Date(b.start_datetime) - new Date(a.start_datetime)
        );
        setFilteredData(data);
        break;
      }
      default:
        setFilteredData(data);
    }
  };

  const statusCode = {
    1: { text: "pending", color: "orange" },
    2: { text: "accepted", color: "blue" },
    3: { text: "completed", color: "green" },
    "-1": { text: "rejected", color: "red" },
  };

  const handleEdit = (id) => {
    console.log("id", id);
  };

  const isToday = (date) => {
    return dayjs(date).isSame(dayjs(), "day");
  };

  const columns = [
    {
      title: "Date Time",
      dataIndex: "start_datetime",
      render: (start_datetime, record) => {
        const containerStyle = {
          display: "flex",
          flexDirection: "column",
          fontSize: "14px",
          lineHeight: "1.5",
          padding: "8px",
        };

        const dateStyle = {
          fontWeight: "bold",
          marginBottom: "4px",
        };

        const timeStyle = {
          fontSize: 11,
        };

        return (
          <div style={containerStyle}>
            <div style={dateStyle}>
              {monthDayAndYear(start_datetime)}
              {isToday(start_datetime) && (
                <Tag color="#f50" style={{ marginLeft: 10 }}>
                  Today
                </Tag>
              )}
            </div>
            <div style={timeStyle}>Start: {onlyTime(start_datetime)}</div>
            <div style={timeStyle}>End: {onlyTime(record.end_datetime)}</div>
          </div>
        );
      },
      // sorter: (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime),
    },
    {
      title: "Student Name",
      dataIndex: "studentuser",
      render: (studentuser) => {
        return (
          <span style={{ display: "block" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img
                  src={studentuser.profile_picture}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div>
                <h6 style={{ fontSize: "13px" }}>
                  {studentuser.name?.split("+").join(" ")}
                </h6>
                <div
                  style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
                >
                  {studentuser.email}
                </div>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: "Instructor Name",
      dataIndex: "instructor",
      render: (instructor) => {
        return (
          <span style={{ display: "block" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img
                  src={instructor.profile_picture}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div>
                <h6 style={{ fontSize: "13px" }}>
                  {instructor.name?.split("+").join(" ")}
                </h6>
                <div
                  style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
                >
                  {instructor.email}
                </div>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag color={statusCode[status].color}>{statusCode[status].text}</Tag>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (remarks) => (
        <div
          style={{
            width: "200px",
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {remarks || "No remarks"}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (id) => (
        <Button type="primary" size="small" onClick={() => handleEdit(id)}>
          Edit
        </Button>
      ),
    },
  ];

  const pagination = {
    pageSize: 5,
    total: filteredData.length,
  };

  const handleFilterChange = (value) => {
    setFilterOption(value);
  };

  return (
    <>
      <Col xs={24} style={{ marginTop: "125px" }}>
        <Card className="recentCard">
          <div
            style={{
              display: "flex",
              padding: "22px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{ marginBottom: "0", fontSize: "16px", fontWeight: "500" }}
            >
              All Reservation
            </h4>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Select
                defaultValue="today"
                style={{ width: 150, marginRight: "16px" }}
                onChange={handleFilterChange}
              >
                <Option value="today">Today</Option>
                <Option value="oldest">Oldest</Option>
                <Option value="latest">Latest</Option>
              </Select>
              {/* <Dropdown menu={{ items: [] }} trigger={["click"]}>
                <a
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: customStyles.colorText }}
                >
                  <Space>
                    <Text style={{ fontSize: "12px", fontWeight: "500" }}>
                      SORT BY:{" "}
                    </Text>
                    <span
                      style={{
                        fontWeight: "400",
                        color: customStyles.textMuted,
                        fontSize: "12px",
                      }}
                    >
                      Today
                      <ChevronDown size={15} />
                    </span>
                  </Space>
                </a>
              </Dropdown> */}
            </div>
          </div>

          {/* Table */}
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Table
              columns={columns || []}
              dataSource={filteredData.map((reservation, index) => ({
                ...reservation,
                key: index,
              }))}
              pagination={pagination}
            />
          </div>
        </Card>
      </Col>
    </>
  );
}

export default AllReservation;
