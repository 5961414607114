// import { useState, useMemo } from "react";
// import { useSelector } from "react-redux";
// import { Table, Tag, Select } from "antd";
// import {
//   extractContent,
//   monthDayAndYear,
//   onlyTime,
// } from "../../Util/helperFunctions";

// const { Option } = Select;

// function AllNotification() {
//   const { allNotification } = useSelector((state) => state.Notifications);
//   const [selectedType, setSelectedType] = useState(null);
//   const [selectedStatus, setSelectedStatus] = useState(null);

//   // Extract unique notification types
//   const notificationTypes = useMemo(() => {
//     const types = allNotification?.data?.map(
//       (notification) => notification.notification_type.type
//     );
//     return [...new Set(types)];
//   }, [allNotification]);

//   // Extract unique read statuses
//   const readStatuses = useMemo(() => {
//     const statuses = allNotification?.data?.map(
//       (notification) => notification.read_status
//     );
//     return [...new Set(statuses)];
//   }, [allNotification]);

//   // Filter notifications based on selected type and status
//   const filteredNotifications = useMemo(() => {
//     return allNotification?.data?.filter((notification) => {
//       const matchesType = selectedType
//         ? notification.notification_type.type === selectedType
//         : true;
//       const matchesStatus = selectedStatus
//         ? notification.read_status === selectedStatus
//         : true;
//       return matchesType && matchesStatus;
//     });
//   }, [selectedType, selectedStatus, allNotification]);

//   const columns = [
//     {
//       title: "Notification Type",
//       dataIndex: "notification_type",
//       key: "notification_type",
//       render: (notification_type) => (
//         <Tag color="#1c1413" style={{ fontSize: 13 }}>
//           {notification_type.type}
//         </Tag>
//       ),
//     },
//     {
//       title: "Trigger User",
//       dataIndex: "trigger_user",
//       key: "trigger_user",
//       render: (trigger_user) => (
//         <span style={{ display: "block" }}>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               // justifyContent: "center",
//             }}
//           >
//             <div>
//               <img
//                 src={trigger_user.profile_picture}
//                 alt=""
//                 style={{
//                   borderRadius: "50%",
//                   height: "38px",
//                   width: "38px",
//                   marginRight: "10px",
//                 }}
//               />
//             </div>
//             <div>
//               <h6 style={{ fontSize: "13px" }}>
//                 {trigger_user.name?.split("+").join(" ")}
//               </h6>
//               <div
//                 style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
//               >
//                 {trigger_user.email}
//               </div>
//             </div>
//           </div>
//         </span>
//       ),
//     },
//     {
//       title: "Notify User",
//       dataIndex: "notify_user",
//       key: "notify_user",
//       render: (notify_user) => (
//         <span style={{ display: "block" }}>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               // justifyContent: "center",
//             }}
//           >
//             <div>
//               <img
//                 src={notify_user.profile_picture}
//                 alt=""
//                 style={{
//                   borderRadius: "50%",
//                   height: "38px",
//                   width: "38px",
//                   marginRight: "10px",
//                 }}
//               />
//             </div>
//             <div>
//               <h6 style={{ fontSize: "13px" }}>
//                 {notify_user.name?.split("+").join(" ")}
//               </h6>
//               <div
//                 style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
//               >
//                 {notify_user.email}
//               </div>
//             </div>
//           </div>
//         </span>
//       ),
//     },
//     {
//       title: "Status",
//       dataIndex: "read_status",
//       key: "read_status",
//       render: (text, record) => {
//         return (
//           <Tag color={record.read_status === "unread" ? "#f50" : ""}>
//             {record.read_status}
//           </Tag>
//         );
//       },
//     },
//   ];

//   const expandedRowRender = (record) => {
//     return (
//       <div
//         style={{
//           padding: "16px",
//           // backgroundColor: "#dee2e6",
//           backgroundColor: "#bde0fe",
//           borderRadius: "8px",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           gap: "5px",
//         }}
//       >
//         <div
//           style={{
//             // backgroundColor: "yellow",
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold" }}>
//             Notification Content
//           </h4>

//           <p style={{ marginBottom: "0", fontSize: "14px", color: "#595959" }}>
//             {extractContent(record.additional_content)?.split("+")?.join(" ") ||
//               record.notification_type.content}
//           </p>
//         </div>
//         <div
//           style={{
//             // backgroundColor: "coral",
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold" }}>
//             Created At
//           </h4>
//           <p style={{ marginBottom: "0", fontSize: "14px", color: "#595959" }}>
//             {monthDayAndYear(record.notification_type.created_at)} -{" "}
//             {onlyTime(record.notification_type.created_at)}
//           </p>
//         </div>
//         <div
//           style={{
//             // backgroundColor: "tomato",
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold" }}>
//             Updated At
//           </h4>
//           <p style={{ marginBottom: "0", fontSize: "14px", color: "#595959" }}>
//             {monthDayAndYear(record.notification_type.updated_at)} -{" "}
//             {onlyTime(record.notification_type.updated_at)}
//           </p>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div>
//       <Select
//         placeholder="Select notification type"
//         style={{ width: 200, marginBottom: 16, marginTop: 48 }}
//         onChange={(value) => setSelectedType(value)}
//         allowClear
//       >
//         {notificationTypes.map((type) => (
//           <Option key={type} value={type}>
//             {type}
//           </Option>
//         ))}
//       </Select>
//       <Select
//         placeholder="Select read status"
//         style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
//         onChange={(value) => setSelectedStatus(value)}
//         allowClear
//       >
//         {readStatuses.map((status) => (
//           <Option key={status} value={status}>
//             {status}
//           </Option>
//         ))}
//       </Select>
//       <Table
//         columns={columns}
//         dataSource={filteredNotifications}
//         rowKey="id"
//         expandable={{ expandedRowRender }}
//       />
//     </div>
//   );
// }

// export default AllNotification;

// ----------------------------------------------------------------
// ----------------------------------------------------------------

// not sure working or not (upper code is working)
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Table, Tag, Select } from "antd";
import {
  extractContent,
  monthDayAndYear,
  onlyTime,
} from "../../Util/helperFunctions";
import moment from "moment";

const { Option } = Select;

function AllNotification() {
  const { allNotification } = useSelector((state) => state.Notifications);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [dateFilter, setDateFilter] = useState("today");

  // Extract unique notification types
  const notificationTypes = useMemo(() => {
    if (!allNotification || !allNotification.data) return [];
    const types = allNotification.data.map(
      (notification) => notification.notification_type.type
    );
    return [...new Set(types)];
  }, [allNotification]);

  // Extract unique read statuses
  const readStatuses = useMemo(() => {
    if (!allNotification || !allNotification.data) return [];
    const statuses = allNotification.data.map(
      (notification) => notification.read_status
    );
    return [...new Set(statuses)];
  }, [allNotification]);

  // Filter notifications based on selected type, status, and date filter
  const filteredNotifications = useMemo(() => {
    if (!allNotification || !allNotification.data) return [];
    const today = moment().startOf("day");

    const filtered = allNotification.data.filter((notification) => {
      const matchesType = selectedType
        ? notification.notification_type.type === selectedType
        : true;
      const matchesStatus = selectedStatus
        ? notification.read_status === selectedStatus
        : true;
      return matchesType && matchesStatus;
    });

    const sortedNotifications = filtered.slice().sort((a, b) => {
      if (dateFilter === "latest") {
        return (
          moment(b.notification_type.created_at) -
          moment(a.notification_type.created_at)
        );
      } else if (dateFilter === "oldest") {
        return (
          moment(a.notification_type.created_at) -
          moment(b.notification_type.created_at)
        );
      }
      return 0;
    });

    if (dateFilter === "today") {
      const todayNotifications = sortedNotifications.filter((notification) =>
        moment(notification.notification_type.created_at).isSame(today, "day")
      );
      const otherNotifications = sortedNotifications.filter(
        (notification) =>
          !moment(notification.notification_type.created_at).isSame(
            today,
            "day"
          )
      );
      return [...todayNotifications, ...otherNotifications];
    }

    return sortedNotifications;
  }, [selectedType, selectedStatus, dateFilter, allNotification]);

  const columns = [
    {
      title: "Notification Type",
      dataIndex: "notification_type",
      key: "notification_type",
      render: (notification_type) => (
        <Tag color="#1c1413" style={{ fontSize: 13 }}>
          {notification_type.type}
        </Tag>
      ),
    },
    {
      title: "Trigger User",
      dataIndex: "trigger_user",
      key: "trigger_user",
      render: (trigger_user) => (
        <span style={{ display: "block" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={trigger_user.profile_picture}
                alt=""
                style={{
                  borderRadius: "50%",
                  height: "38px",
                  width: "38px",
                  marginRight: "10px",
                }}
              />
            </div>
            <div>
              <h6 style={{ fontSize: "13px" }}>
                {trigger_user.name?.split("+").join(" ")}
              </h6>
              <div
                style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
              >
                {trigger_user.email}
              </div>
            </div>
          </div>
        </span>
      ),
    },
    {
      title: "Notify User",
      dataIndex: "notify_user",
      key: "notify_user",
      render: (notify_user) => (
        <span style={{ display: "block" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={notify_user.profile_picture}
                alt=""
                style={{
                  borderRadius: "50%",
                  height: "38px",
                  width: "38px",
                  marginRight: "10px",
                }}
              />
            </div>
            <div>
              <h6 style={{ fontSize: "13px" }}>
                {notify_user.name?.split("+").join(" ")}
              </h6>
              <div
                style={{ fontSize: 11, marginLeft: "1px", color: "#808080" }}
              >
                {notify_user.email}
              </div>
            </div>
          </div>
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "read_status",
      key: "read_status",
      render: (text, record) => {
        return (
          <Tag color={record.read_status === "unread" ? "#f50" : ""}>
            {record.read_status}
          </Tag>
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <div
        style={{
          padding: "16px",
          backgroundColor: "#bde0fe",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <div
          style={{
            width: "30%",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "5px", fontWeight: "bold" }}>
            Notification Content
          </h4>

          <p style={{ marginBottom: "0", fontSize: "14px", color: "#595959" }}>
            {extractContent(record.additional_content)?.split("+")?.join(" ") ||
              record.notification_type.content}
          </p>
        </div>
        <div
          style={{
            width: "30%",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "5px", fontWeight: "bold" }}>
            Created At
          </h4>
          <p style={{ marginBottom: "0", fontSize: "14px", color: "#595959" }}>
            {monthDayAndYear(record.notification_type.created_at)} -{" "}
            {onlyTime(record.notification_type.created_at)}
          </p>
        </div>
        <div
          style={{
            width: "30%",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "5px", fontWeight: "bold" }}>
            Updated At
          </h4>
          <p style={{ marginBottom: "0", fontSize: "14px", color: "#595959" }}>
            {monthDayAndYear(record.notification_type.updated_at)} -{" "}
            {onlyTime(record.notification_type.updated_at)}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Select
        placeholder="Select notification type"
        style={{ width: 200, marginBottom: 16, marginTop: 48 }}
        onChange={(value) => setSelectedType(value)}
        allowClear
      >
        {notificationTypes.map((type) => (
          <Option key={type} value={type}>
            {type}
          </Option>
        ))}
      </Select>
      <Select
        placeholder="Select read status"
        style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
        onChange={(value) => setSelectedStatus(value)}
        allowClear
      >
        {readStatuses.map((status) => (
          <Option key={status} value={status}>
            {status}
          </Option>
        ))}
      </Select>
      <Select
        placeholder="Select date filter"
        style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
        onChange={(value) => setDateFilter(value)}
        value={dateFilter}
      >
        <Option value="today">Today</Option>
        <Option value="latest">Latest</Option>
        <Option value="oldest">Oldest</Option>
      </Select>
      <Table
        columns={columns}
        dataSource={filteredNotifications}
        rowKey="id"
        expandable={{ expandedRowRender }}
      />
    </div>
  );
}

export default AllNotification;
