export function formatDateString(dateString) {
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Define options for the date part
  const dateOptions = {
    month: "long", // Full month name
    day: "numeric", // Numeric day of the month
  };

  // Define options for the time part
  const timeOptions = {
    hour: "numeric", // Hour in 12-hour format
    minute: "numeric", // Minute
    hour12: true, // 12-hour format with AM/PM
  };

  // Format the date and time parts separately
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  // Combine the formatted date and time with a comma
  return `${formattedDate}, ${formattedTime}`;
}

export function monthDayAndYear(dateString) {
  const date = new Date(dateString);

  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export function onlyTime(dateTimeString) {
  const date = new Date(dateTimeString);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes; // Add leading zero to minutes if needed

  const timeString = `${hours}:${minutesStr} ${ampm}`;
  return timeString;
}

export function extractContent(htmlString) {
  // Create a temporary DOM element to parse the HTML string
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Extract the text content from the temporary element
  let extractedContent = tempDiv.textContent || tempDiv.innerText || "";

  return extractedContent;
}
