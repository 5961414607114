// import { LoadingOutlined } from "@ant-design/icons";
// import { Spin } from "antd";
// import React, { useEffect } from "react"

// const antIcon = (
//     <LoadingOutlined
//       style={{
//         fontSize: 50,
//       }}
//       spin
//     />
//   );

// const Spinners = ({ setLoading }) => {

//     useEffect(() => {
//         setTimeout(() => {
//             setLoading(false)
//         }, 1000)
//     }, [setLoading]);

//     return (
//         <React.Fragment>
//             <Spin indicator={antIcon} style={{display: "flex", alignSelf: "center", paddingLeft: "50%", paddingTop: "10%" }} />
//         </React.Fragment>
//     )
// }

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);

const Spinners = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)", // semi-transparent background
        zIndex: 9999, // ensure the spinner appears above other content
      }}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Spinners;
